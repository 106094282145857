@if(toolbar) {
  <mat-toolbar class="app-table-toolbar">
    @if (search) {
      @let disabled = onNoData | async;
      <app-search [disabled]="disabled" [placeholder]="'common.search.' + collection"></app-search>

      <button mat-icon-button
        [disabled]="disabled"
        (click)="openCustomSearchDialog()"
        [tooltip]="'common.custom_search' | translate"
        tooltipTutorial="29"
      >
        <mat-icon [matBadge]="(isDefaultSearchParameters | async) ? null : '!'" aria-hidden="false">tune</mat-icon>
      </button>
    }

    <div class="spacer"></div>

    <button mat-button
            class="edit-columns royal-text-secondary"
            [class.hidden]="onNoData | async"
            (click)="openEditColumnsDialog()"
            [tooltip]="'common.edit_columns' | translate"
            tooltipTutorial="16"
            cypress="open-edit-columns-dialog">
      <mat-icon class="material-symbols-outlined">view_week</mat-icon>
      {{ 'common.edit_columns' | translate }}
    </button>

    <!-- <button mat-mini-fab color="primary" class="add" *ngIf="add" [matTooltip]="'common.create_event' | translate" (click)="openCreateGroupDialog('events')">
      <mat-icon>add</mat-icon>
    </button> -->
  </mat-toolbar>
}

<div class="app-table-container" #tableContainer>
  <app-empty collection="events" [visible]="onNoData | async"></app-empty>
  <app-no-columns [visible]="noDataColumns | async"></app-no-columns>
  <app-loading [loading]="dataSource.loading$ | async"></app-loading>

  <table #table mat-table
    [dataSource]="dataSource"
    [trackBy]="trackBy"
    matSort
  >

    <ng-container matColumnDef="select" sticky>
      <th mat-header-cell *matHeaderCellDef class="colored-border transparent-right-border">
        <mat-checkbox (change)="$event ? selection.toggleAll() : null"
                      [checked]="selection.checked"
                      [indeterminate]="selection.indeterminate"
                      [disabled]=" ! editable">
        </mat-checkbox>
        <div class="divider three-px"></div>
      </th>
      <td mat-cell *matCellDef="let row; table: table" class="colored-border">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [disabled]=" ! editable">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="ids">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.ids" [description]="columnDescriptionTranslationKeys.ids"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-text [value]="element.ids"
                              saveOnFocusLost
                              nullifyEmpty
                              *ngIf="editable"
                              (onChange)="edit(element.id, { ids: $event })">
        </app-form-field-text>
        <div *ngIf="! editable" class="overflow-text">{{ element.ids }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.createdAt" [description]="columnDescriptionTranslationKeys.createdAt"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div class="overflow-text">{{ element.createdAt }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.tags"
                            [description]="columnDescriptionTranslationKeys.tags"
                            tutorial="39"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-tags
          [value]="element.tags"
          [disabled]=" ! editable"
          [tagOptions]="tags$ | async"
          nullable
          saveOnClose
          (onChange)="edit(element.id, { tags: $event })"
        >
        </app-form-field-tags>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.displayName" [description]="columnDescriptionTranslationKeys.displayName"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-text [value]="element.displayName"
                              saveOnFocusLost
                              *ngIf="editable"
                              (onChange)="edit(element.id, { displayName: $event })">
        </app-form-field-text>
        <div *ngIf="! editable" class="overflow-text">{{ element.displayName }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="course">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="course.displayName">
        <app-column-header [title]="columnTitleTranslationKeys.course" [description]="columnDescriptionTranslationKeys.course"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div *ngIf="element.course?.displayName" class="course-name">{{ element.course?.displayName }}</div>
        <div *ngIf=" ! element.course?.displayName" class="course-name nameless">{{ 'common.nameless' | translate}}</div>
        {{ preferences.displayPublicId && element.course?.ids ? '(' + element.course?.ids + ')' : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="course.subject">
        <app-column-header [title]="columnTitleTranslationKeys.subject" [description]="columnDescriptionTranslationKeys.subject"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div class="course-subject">{{ element.course?.subject }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="durationVariance">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.durationVariance"
                            [description]="columnDescriptionTranslationKeys.durationVariance"
                            tutorial="25">
        </app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-text [value]="element.durationVariance"
                    saveOnFocusLost
                    nullifyEmpty
                    validate="multiple-of-five"
                    maxWidth="2em"
                    class="push-suffix-left"
                    [voidText]="element.course?.eventDurationVariance ?? (settings | async)?.defaultEventDurationVariance"
                    prefix="±"
                    [suffix]="'common.minutes' | translate | lowercase"
                    *ngIf="editable"
                    (onChange)="edit(element.id, { durationVariance: toNumber($event) })">
        </app-form-field-text>
        @if (! editable) {
          <div class="overflow-text">
            @let variation = element.durationVariance ?? element.course?.eventDurationVariance ?? (settings | async)?.defaultEventDurationVariance;
            ± {{ variation }} {{ 'common.minutes' | translate | lowercase }}
          </div>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="preferredDuration">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.preferredDuration" [description]="columnDescriptionTranslationKeys.preferredDuration"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-duration *ngIf="editable"
                                  [value]="element.preferredDuration"
                                  [secondary]="element.duration"
                                  isPreferredDuration
                                  voidText="??"
                                  [suffix]="'common.minutes' | translate | lowercase"
                                  (onChange)="edit(element.id, { preferredDuration: $event })">
        </app-form-field-duration>
        <app-form-field-duration-display-value [value]="element.preferredDuration"
                                                [secondary]="element.duration"
                                                isPreferredDuration
                                                voidText="??"
                                                [suffix]="'common.minutes' | translate | lowercase"
                                                *ngIf="! editable">
        </app-form-field-duration-display-value>
        <!-- <app-form-field-text [value]="element.preferredDuration"
                    saveOnFocusLost
                    validate="multiple-of-5-min-0"
                    maxWidth="2em"
                    [suffix]="(element.duration && element.duration != element.preferredDuration ? '(' + element.duration + ') ' : '') + ('common.minutes' | translate | lowercase)"
                    *ngIf="editable"
                    (onChange)="edit(element.id, { preferredDuration: toNumber($event) })">
        </app-form-field-text>
        <div *ngIf="! editable" class="overflow-text">
          {{ element.preferredDuration }}
          {{ 'common.minutes' | translate | lowercase }}
        </div> -->

      </td>
    </ng-container>

    <ng-container matColumnDef="overlapSpecies">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.overlapSpecies"
                            [description]="columnDescriptionTranslationKeys.overlapSpecies"
                            tutorial="31"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table" [class.clickable]="editable" (click)="editable && openOverlapGroupsDialog(element)">
        @if ( ! element?.course?.overlapGroup?.id) {
          <div class="empty" cypress="empty">
            -
          </div>
        } @else if ( ! element.linkedEvents) {
          <div cypress="not-placed">
            {{ 'common.not_placed' | translate }}
          </div>
        } @else if (element.linkedEvents.length == 0) {
          <div cypress="alone">
            {{ 'common.alone' | translate }}
          </div>
        } @else {
          <div class="events-container" tabindex="0">
            @for (event of element.linkedEvents; track event.id) {
              <div [style.background-color]="event | eventColor:'20'" class="box linked-event-div">
                {{ event | displayName }} - {{ event.preferredDuration }}
              </div>
            }
          </div>
        }
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.duration" [description]="columnDescriptionTranslationKeys.duration"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-duration *ngIf="editable"
                                  [value]="element.duration"
                                  (onChange)="edit(element.id, { duration: $event })">
        </app-form-field-duration>
        <div *ngIf="! editable">{{ element.duration }}</div>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="fixedStart">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.fixedStart"
                            [description]="columnDescriptionTranslationKeys.fixedStart"
                            tutorial="6"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-fixed-start [value]="element"
                                    [numDays]="settings | async | pluck:'numDays'"
                                    *ngIf="editable"
                                    [duration]="element.duration"
                                    [preferredDuration]="element.preferredDuration"
                                    disableActions
                                    voidText="-"
                                    saveOnClose
                                    (onChange)="edit(element.id, $event)">
        </app-form-field-fixed-start>
        <app-form-field-fixed-start-display-value [value]="element.fixedStart ? element.start : null"
                                                  voidText="-"
                                                  *ngIf="! editable">
        </app-form-field-fixed-start-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="minBreakLength">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.minBreakLength"
                            [description]="columnDescriptionTranslationKeys.minBreakLength"
                            tutorial="21"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-break-length [value]="element?.minBreakLength"
                                      *ngIf="editable"
                                      saveOnClose
                                      inherit
                                      [voidText]="(element.course?.minBreakLength | readableBreakLength) || ((settings | async)?.defaultEventMinimumBreakLength | readableBreakLength)"
                                      [setVoidText]="'common.inheritFromCourseValue' | translate:{ value: (element.course?.minBreakLength | readableBreakLength) || ((settings | async)?.defaultEventMinimumBreakLength | readableBreakLength) }"
                                      [defaultValue]="(settings | async)?.defaultEventMinimumBreakLength"
                                      (onChange)="edit(element.id, { minBreakLength: $event })">
        </app-form-field-break-length>
        <app-form-field-break-length-display-value [value]="element.minBreakLength"
                                                    [voidText]="(element.course?.minBreakLength | readableBreakLength) || ((settings | async)?.defaultEventMinimumBreakLength | readableBreakLength)"
                                                    *ngIf="! editable">
        </app-form-field-break-length-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="teachers">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.teachers"
                            [description]="columnDescriptionTranslationKeys.teachers"
                            tutorial="10"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div formField="teachers"
          collection="events"
          path="teachers"
          [id]="element.id"
          [value]="element.teachers"
          [inheritedValue]="element.course?.teachers"
          [editable]="editable"
        >
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="groups">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.groups"
                            [description]="columnDescriptionTranslationKeys.groups"
                            tutorial="10"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div formField="groups-persons"
          collection="events"
          path="groups"
          [id]="element.id"
          [value]="element.groups"
          [inheritedValue]="element.course?.groups"
          [editable]="editable"
          [coalescedValue]="element.participants"
          [inheritedCoalescedValue]="element.course?.participants"
          coalescedPath="participants"
        >
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="locations">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.locations"
                            [description]="columnDescriptionTranslationKeys.locations"
                            tutorial="20"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div
              formField="available-locations"
              collection="events"
              path="locations"
              [value]="element.locations"
              [id]="element.id"
              [inheritedValue]="element.course?.locations"
              [config]="{
                voidText: (element.course?.locations | displayAvailableLocations:onDataChange) || '-',
                shouldReset: true,
                allowAdd: true,
                singleSet: false,
              }"
        ></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="days">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.days"
                            [description]="columnDescriptionTranslationKeys.days"
                            tutorial="8"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-available-days [value]="element.days"
                                        [numDays]="settings | async | pluck:'numDays'"
                                        (onChange)="edit(element.id, { days: $event })"
                                        *ngIf="editable"
                                        inherit
                                        [voidText]="(element.course?.days | dayNames:'short') || ('common.all' | translate)"
                                        saveOnClose>
        </app-form-field-available-days>
        <app-form-field-available-days-display-value [value]="element.days"
                                                      *ngIf="! editable"
                                                      inherit
                                                      [voidText]="(element.course?.days | dayNames:'short') || ('common.all' | translate)">
        </app-form-field-available-days-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="lockedTimes">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.lockedTimes"
                            [description]="columnDescriptionTranslationKeys.lockedTimes"
                            tutorial="9"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-locked-times inherit
                                      [numDays]="settings | async | pluck:'numDays'"
                                      [value]="element.lockedTimes"
                                      [voidText]="(element.course?.lockedTimes | readableLockedTimes) || '-'"
                                      *ngIf="editable"
                                      saveOnClose
                                      (onChange)="edit(element.id, { lockedTimes: $event })">
        </app-form-field-locked-times>
        <app-form-field-locked-times-display-value inherit
                                                    [value]="element.lockedTimes"
                                                    [voidText]="(element.course?.lockedTimes | readableLockedTimes) || '-'"
                                                    *ngIf="! editable">
        </app-form-field-locked-times-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="intervals">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.intervals"
                            [description]="columnDescriptionTranslationKeys.intervals"
                            tutorial="3"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-intervals [value]="element.intervals"
                                  [numDays]="settings | async | pluck:'numDays'"
                                  *ngIf="editable"
                                  [availableDays]="element.days"
                                  [inheritAvailableDays]="element.course?.days"
                                  [setVoidText]="'common.inheritFromCourse' | translate"
                                  saveOnClose
                                  inherit
                                  [inheritValue]="element.course?.intervals"
                                  [voidText]="(settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd"
                                  (onChange)="edit(element.id, { intervals: $event })">
        </app-form-field-intervals>
        <app-form-field-intervals-display-value [value]="element.intervals"
                                                [numDays]="settings | async | pluck:'numDays'"
                                                *ngIf="! editable"
                                                [availableDays]="element.days"
                                                [inheritAvailableDays]="element.course?.days"
                                                inherit
                                                [inheritValue]="element.course?.intervals"
                                                [voidText]="(settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd">
        </app-form-field-intervals-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.period"
                            [description]="columnDescriptionTranslationKeys.period"
                            tutorial="27"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-period [value]="element.period ?? null"
                                inherit
                                [voidText]="(element.course?.period?.displayName ?? (settings | async)?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst"
                                [setVoidText]="'common.inheritFromCourseValue' | translate:{ value: (element.course?.period?.displayName ?? (settings | async)?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst }"
                                disableActions
                                *ngIf="editable"
                                saveOnClose
                                (onChange)="edit(element.id, { period: $event })">
        </app-form-field-period>
        <app-form-field-period-display-value [value]="element.period"
                                              *ngIf="! editable"
                                              inherit
                                              [voidText]="element.course?.period || (settings | async)?.period | displayName">
        </app-form-field-period-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="centerOfAttraction">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.centerOfAttraction" [description]="columnDescriptionTranslationKeys.centerOfAttraction"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-center-of-attraction [value]="element.centerOfAttraction ?? null"
                                              [preselected]="element.course?.centerOfAttraction || (settings | async)?.potentialCenter"
                                              [voidText]="element.course?.centerOfAttraction || (settings | async)?.potentialCenter"
                                              [setVoidText]="'common.inheritFromCourseValue' | translate:{ value: element.course?.centerOfAttraction || (settings | async)?.potentialCenter }"
                                              *ngIf="editable"
                                              saveOnClose
                                              inherit
                                              (onChange)="edit(element.id, { centerOfAttraction: $event })">
        </app-form-field-center-of-attraction>
        <app-form-field-center-of-attraction-display-value [value]="element.centerOfAttraction"
                                                            *ngIf="! editable"
                                                            inherit
                                                            [voidText]="element.course?.centerOfAttraction || (settings | async)?.potentialCenter">
        </app-form-field-center-of-attraction-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef class="unset-min-width">
        <app-column-header [title]="columnTitleTranslationKeys.color" [description]="columnDescriptionTranslationKeys.color"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table" class="unset-min-width">
        <app-form-field-color (onChange)="edit(element.id, { color: $event })"
                              nullable
                              *ngIf="editable"
                              [setVoidText]="'common.inheritFromCourse' | translate"
                              [nullValue]="element.course?.color ?? defaultEventColor"
                              [value]="element.color">
        </app-form-field-color>
        <app-form-field-color-display-value [color]="element.color ?? defaultEventColor"
                                            *ngIf="! editable">
        </app-form-field-color-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; table: table">
        <div class="flex flex-justify-end">
          @if (appFeatures.CREATE_EVENTS | hasAppFeature) {
              <button mat-icon-button
                      [disabled]=" ! editable"
                      type="button"
                      (click)="copy(element, 'displayName', 'course', 'start', 'end', 'duration', 'durationVariance', 'preferredDuration', 'days', 'tags', 'minBreakLength', 'intervals', 'teachers', 'groups', 'participants', 'locations', 'color', 'potentialCenter', 'period', 'centerOfAttraction')">
                <mat-icon class="royal-icon"
                          matTooltip="{{ 'common.copy' | translate }}">
                  content_copy
                </mat-icon>
              </button>
            }
          <button mat-icon-button
                  [disabled]=" ! editable"
                  type="button"
                  (click)="deleteOne(element.id)"
                  matTooltip="{{ 'common.delete' | translate }}">
            <mat-icon class="royal-icon">delete_outline</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>


    <!----------------------------------------------------------------------->
    <!------------------------------ BULK EDIT ------------------------------>
    <!----------------------------------------------------------------------->
    <ng-container matColumnDef="bulk-select">
      <th mat-header-cell *matHeaderCellDef  class="sticky">
        <div class="collapsible">
          <div class="corner top"></div>
          <div class="corner bottom"></div>
          <div class="selected-count">
            {{ selection.length }}
          </div>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-displayName">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell">
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-course">
      <th mat-header-cell *matHeaderCellDef class="course-name">
        {{ onSelection | async | combineCourseNames }}
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-subject">
      <th mat-header-cell *matHeaderCellDef class="course-subject">
        {{ onSelection | async | combineCourseSubjects }}
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-duration">
      <th mat-header-cell *matHeaderCellDef>
        <app-form-field-duration [value]="durationBulkValue"
                                  (onChange)="editMany(selection.selected, { duration: $event })"
                                  voidText="-"
                                  class="duration"
                                  [class.hidden]="selection.empty">
        </app-form-field-duration>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-durationVariance">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-text [value]="durationVarianceBulkValue"
                      saveOnFocusLost
                      nullifyEmpty
                      validate="multiple-of-five"
                      maxWidth="2em"
                      class="push-suffix-left"
                      voidText=" "
                      prefix="±"
                      [suffix]="'common.minutes' | translate | lowercase"
                      (onChange)="editMany(selection.selected, { durationVariance: toNumber($event) })">
          </app-form-field-text>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-preferredDuration">
      <th mat-header-cell *matHeaderCellDef>
        <app-form-field-duration [value]="preferredDurationBulkValue"
                                  (onChange)="editMany(selection.selected, { preferredDuration: $event })"
                                  voidText="-"
                                  [suffix]="preferredDurationBulkValue != undefined ? ('common.minutes' | translate | lowercase) : ''"
                                  class="duration"
                                  [class.hidden]="selection.empty">
        </app-form-field-duration>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-overlapSpecies">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell">
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-createdAt">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell"></th>
    </ng-container>

    <ng-container matColumnDef="bulk-fixedStart">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell"></th>
    </ng-container>

    <ng-container matColumnDef="bulk-groups">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <div formField="groups-persons"
            collection="events"
            path="groups"
            [value]="groupsBulkValue"
            [editable]="editable"
            [id]="selection.selected"
            [coalescedValue]="participantsBulkValue"
            coalescedPath="participants"
          >
          </div>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-teachers">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <div formField="teachers"
            collection="events"
            path="teachers"
            [value]="teachersBulkValue"
            [editable]="editable"
            [id]="selection.selected"
          >
          </div>
        </div>
      </th>
    </ng-container>


    <ng-container matColumnDef="bulk-locations">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <div
            formField="available-locations"
            collection="events"
            path="locations"
            [value]="locationsBulkValue"
            [id]="selection.selected"
            [config]="{
              voidText: '-',
              shouldReset: true,
              allowAdd: true,
              singleSet: false,
            }"
          ></div>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-overlapGroup">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>

    <ng-container matColumnDef="bulk-days">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-available-days [value]="daysBulkValue"
                                        [numDays]="settings | async | pluck:'numDays'"
                                        voidText="-"
                                        (onChange)="editMany(selection.selected, { days: $event })"
                                        saveOnClose
                                        inherit
                                        disableActions>
          </app-form-field-available-days>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-lockedTimes">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-locked-times inherit
                                      [numDays]="settings | async | pluck:'numDays'"
                                        [value]="lockedTimesBulkValue"
                                        voidText="-"
                                        omitId
                                        disableActions
                                        saveOnClose
                                        (onChange)="editMany(selection.selected, { lockedTimes: $event })">
          </app-form-field-locked-times>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-minBreakLength">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-break-length [value]="minBreakLengthBulkValue"
                                      coalesced
                                      disableActions
                                      saveOnClose
                                      inherit
                                      voidText="-"
                                      [setVoidText]="'common.inheritFromCourse' | translate"
                                      (onChange)="editMany(selection.selected, { minBreakLength: $event })">
          </app-form-field-break-length>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-intervals">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">

          <app-form-field-intervals [value]="intervalsBulkValue"
                                    [numDays]="settings | async | pluck:'numDays'"
                                    voidText="-"
                                    [setVoidText]="'common.inheritFromCourse' | translate"
                                    disableActions
                                    saveOnClose
                                    (onChange)="editMany(selection.selected, { intervals: $event })">
          </app-form-field-intervals>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-period">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-period [value]="periodBulkValue"
                                  inherit
                                  voidText="-"
                                  [setVoidText]="'common.inheritFromCourse' | translate"
                                  disableActions
                                  saveOnClose
                                  (onChange)="editMany(selection.selected, { period: $event })">
          </app-form-field-period>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-centerOfAttraction">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-center-of-attraction [value]="centerOfAttractionBulkValue"
                                                [preselected]="(settings | async)?.potentialCenter"
                                                voidText="-"
                                                [setVoidText]="'common.inheritFromCourse' | translate"
                                                disableActions
                                                saveOnClose
                                                (onChange)="editMany(selection.selected, { centerOfAttraction: $event })">
          </app-form-field-center-of-attraction>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-color">
      <th mat-header-cell *matHeaderCellDef class="unset-min-width">
        <div class="collapsible">
          <app-form-field-color [value]="colorBulkValue"
                                coalesced
                                [setVoidText]="'common.inheritFromCourse' | translate"
                                (onChange)="editMany(selection.selected, { color: $event })">
          </app-form-field-color>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-ids">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell"></th>
    </ng-container>

    <ng-container matColumnDef="bulk-tags">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-tags
            [value]="tagsBulkValue"
            [disabled]=" ! editable"
            [tagOptions]="tags$ | async"
            nullable
            saveOnClose
            (onChange)="bulkUpdateTags(selection.selection, $event)"
          >
          </app-form-field-tags>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-actions">
      <th mat-header-cell *matHeaderCellDef class="sticky">
        <div class="collapsible flex-align-center">
          <div class="border"></div>
          <div class="corner top"></div>
          <div class="corner bottom"></div>
          <span class="spacer"></span>
          <button mat-icon-button
                  [disabled]=" ! editable"
                  type="button"
                  class="bulk-delete"
                  (click)="deleteMany(selection.selected)">
            <mat-icon class="royal-icon"
                      matTooltip="{{ 'common.delete' | translate }}"
                      [matBadge]="selection.length"
                      matBadgeOverlap="true"
                      matBadgeColor="warn"
                      aria-hidden="false">delete_outline</mat-icon>
          </button>
        </div>
      </th>
    </ng-container>

    <!-- a column that absorbs width if for example no column is visible -->
    <!-- the colspan=2 also fixes the stickyEnd bug where columns are positioned above the table header -->
    <ng-container matColumnDef="absorber">
      <!-- <th mat-header-cell *matHeaderCellDef colspan="2"></th> -->
      <th mat-header-cell *matHeaderCellDef colspan="1"></th>
      <td mat-cell *matCellDef="let element; table: table"></td>
    </ng-container>
    <ng-container matColumnDef="bulk-absorber">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; table: table"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>

    <!-- bulk edit table header -->
    <tr mat-header-row *matHeaderRowDef="bulkColumns;"
        class="bulk" [class.hidden]="selection.empty" [class.animate]="dataSource.loading$ | async | not"></tr>

    <tr mat-row
        class="table-row"
        *matRowDef="let row; columns: columns;"
        [style.--bg-color]="(row.color ?? row.course?.color ?? defaultEventColor)">
    </tr>

  </table>
</div>

@if(paginator) {
  <mat-paginator showFirstLastButtons
                  [length]="dataSource ? (dataSource.totNumDocs$ | async) : 0"
                  [pageIndex]="0"
                  [pageSize]="pageSize"
                  [pageSizeOptions]="pageSizes">
  </mat-paginator>
}