import { Component, ViewChild, AfterViewInit, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Observable, combineLatest } from 'rxjs';
import { takeUntil, map, filter, debounceTime, shareReplay } from 'rxjs/operators';
import { DivisionSettings, Populated as P, PartialTags } from 'app/shared/interfaces';
import { EventComponent as Form } from '@app/view/private/schedule/subpages/editor/components/edit-course-and-event/components/event/event.component';
import { commonConstants } from 'app/constants';
import { sourceSelectPipe } from 'app/core';
import { SelectionService } from 'app/shared/services/selection/selection.service';
import { ChangeEvent } from 'app/core/source/core/types';
import { Collection } from '../types';
import { COLLECTION } from '../constants';
import { TableCore } from '../table-core';
import { TableColumnsService } from '../services/table-columns/table-columns.service';
import { CustomSearchService } from '../services/custom-search/custom-search.service';
import { StateService } from '../services/state/state.service';
import { tableColumnsOverride$ } from './util';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';


const collection = 'events' satisfies Collection;
type RowType = P.event & { linkedEvents?: P.event[] };

@Component({
    selector: 'app-events-table',
    templateUrl: './events.component.html',
    styleUrl: './events.component.scss',
    providers: [
        { provide: COLLECTION, useValue: collection },
        DataSourceService,
        SelectionService,
        TableColumnsService,
        CustomSearchService,
        StateService,
    ],
    standalone: false
})
export class EventsComponent extends TableCore<typeof collection, RowType> implements AfterViewInit {

  @ViewChild(Form)
  protected form?: Form;
  protected settings:  Observable<DivisionSettings>;
  protected groups:    Observable<P.group[]>;
  protected persons:   Observable<P.person[]>;
  protected teachers:  Observable<P.teacher[]>;
  protected locations: Observable<P.location[]>;
  protected periods:   Observable<P.period[]>;

  protected defaultEventColor = commonConstants.COLORS.EVENT_DEFAULT;

  // used to accurately display update inherited values
  protected onDataChange: Observable<ChangeEvent<P.course>>;

  protected preferredDurationBulkValue:  null | undefined | NonNullable<P.event['preferredDuration']>;
  protected durationVarianceBulkValue:   null | undefined | NonNullable<P.event['durationVariance']>;
  protected durationBulkValue:           null | undefined | NonNullable<P.event['duration']>;
  protected groupsBulkValue:             null | undefined | NonNullable<P.event['groups']>;
  protected teachersBulkValue:           null | undefined | NonNullable<P.event['teachers']>;
  protected locationsBulkValue:          null | undefined | NonNullable<P.event['locations']>;
  protected daysBulkValue:               null | undefined | NonNullable<P.event['days']>;
  protected intervalsBulkValue:          null | undefined | NonNullable<P.event['intervals']>;
  protected periodBulkValue:             null | undefined | NonNullable<P.event['period']>;
  protected centerOfAttractionBulkValue: null | undefined | NonNullable<P.event['centerOfAttraction']>;
  protected lockedTimesBulkValue:        null | undefined | NonNullable<P.event['lockedTimes']>;
  protected minBreakLengthBulkValue:     null | undefined | NonNullable<P.event['minBreakLength']>;
  protected colorBulkValue:              null | undefined | NonNullable<P.event['color']>;
  protected participantsBulkValue:       null | undefined | NonNullable<P.event['participants']>;
  protected tagsBulkValue:               null | undefined | PartialTags;

  protected onSelection = this.selection.onSelection();

  constructor () {
    // In case we open the table from within the courses table the latter one should determine what columns are visible
    super({ tableColumnsOverride$: tableColumnsOverride$() });
  }

// so that the type of the row is correct
private getPopulatedEventsWrapper(...args: Parameters<typeof this._source.getPopulatedEvents>): Observable<RowType[]> {
  return this._source.getEventsNew(...args);
}

  ngAfterViewInit () {
    super.ngAfterViewInit();

    this.afterSourceGroupBy(
      ['groups', 'teachers', 'persons', 'locations', 'settings', 'events', 'courses', 'periods', 'overlapGroups'],
      [],
      this.getPopulatedEventsWrapper.bind(this)
    )
    .then(() => {

      const combined = combineLatest({
        data:          this.data,
        overlapGroups: this._source.getPopulatedOverlapGroups({ did: this.did, onDestroy: this.onDestroy }),
      })
      .pipe(
        debounceTime(0),
        map(({ data, overlapGroups }) => {

          // attach linked events
          data?.docs.forEach(event => {
            const overlapSpecies = event.overlapSpecies;
            if (overlapSpecies) {
              const overlapGroup = overlapGroups.find(x => x.id === overlapSpecies.id);
              const speciesId    = overlapGroup?.species?.find(x => x.to.id === event.id)?.id;
              event.linkedEvents = overlapGroup?.species
                ?.filter(x => x.id == speciesId)
                ?.map(x => Object.assign(x.to, { is: 'event' }))
                ?.filter(x => x.id !== event.id);
            }
          });

          return data;
        }),
        shareReplay(1),
        filter(Boolean)
      );

      this.dataSource.init({ source: combined });
      this.selection.dataSource = this.dataSource;

      this.settings  = this._source.getStrictSettings    ({ did: this.did, onDestroy: this.onDestroy });
      this.teachers  = this._source.getPopulatedTeachers ({ did: this.did, onDestroy: this.onDestroy });
      this.groups    = this._source.getPopulatedGroups   ({ did: this.did, onDestroy: this.onDestroy });
      this.locations = this._source.getPopulatedLocations({ did: this.did, onDestroy: this.onDestroy });
      this.periods   = this._source.getPopulatedPeriods  ({ did: this.did, onDestroy: this.onDestroy });
      this.persons   = this._source.getPopulatedStudents ({ did: this.did, onDestroy: this.onDestroy });

      // to rerender inherited values
      this.onDataChange = this._source.onCoursesChange({ did: this.did, onDestroy: this.onDestroy });

      this.subscribeToSelection();
    });
  }

  // subscribes to selection events
  private subscribeToSelection () {
    this._source
    .getPopulatedEvents({ did: this.did, skipNoFilter: true, onDestroy: this.onDestroy },
      sourceSelectPipe(
        this.selection.onSelection().pipe(map(x => ({ 'id': x.map(y => y.id) })))
      ))
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      debounceTime(200)
    )
    .subscribe(xs => {
      this.preferredDurationBulkValue  = TableCore.isSame(xs.map(x => x.preferredDuration));
      this.durationVarianceBulkValue   = TableCore.isSame(xs.map(x => x.durationVariance   ?? null));
      this.durationBulkValue           = TableCore.isSame(xs.map(x => x.duration           ?? null));
      this.groupsBulkValue             = TableCore.isSame(xs.map(x => x.groups             ?? []));
      this.teachersBulkValue           = TableCore.isSame(xs.map(x => x.teachers           ?? []));
      this.participantsBulkValue       = TableCore.isSame(xs.map(x => x.participants       ?? []));
      this.locationsBulkValue          = TableCore.isSame(xs.map(x => x.locations          ?? []));
      this.daysBulkValue               = TableCore.isSame(xs.map(x => x.days               ?? []), ['day', 'rank']);
      this.intervalsBulkValue          = TableCore.isSame(xs.map(x => x.intervals          ?? []), ['start', 'end']);
      this.periodBulkValue             = TableCore.isSame(xs.map(x => x.period             ?? null));
      this.centerOfAttractionBulkValue = TableCore.isSame(xs.map(x => x.centerOfAttraction ?? null));
      this.lockedTimesBulkValue        = TableCore.isSame(xs.map(x => x.lockedTimes        ?? []), ['start', 'end', 'displayName']);
      this.minBreakLengthBulkValue     = TableCore.isSame(xs.map(x => x.minBreakLength     ?? null));
      this.colorBulkValue              = TableCore.isSame(xs.map(x => x.color              ?? this.defaultEventColor));
      this.tagsBulkValue               = TableCore.getSelectedTags(xs);
    });
  }

  protected create(event: Partial<P.event>): void {
    throw 'IMPLEMENT ME!'
  }

  protected openOverlapGroupsDialog (element: RowType): void {
    this._dialog.openOverlapGroupsDialog(this._viewContainerRef.injector, element.overlapSpecies?.id ?? element?.course?.overlapGroup?.id);
  }


  @Input()
  get minContent(): boolean { return this._minContent; }
  set minContent(value: boolean) {
    this._minContent = coerceBooleanProperty(value);
  }
  private _minContent: boolean;
}
