import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { SharedDirectivesModule                } from 'app/shared/directives/directives.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { ColumnHeaderComponent           } from './column-header.component';

@NgModule({
  declarations: [
    ColumnHeaderComponent,
  ],
  exports: [
    ColumnHeaderComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    SharedDirectivesModule
  ]
})
export class ColumnHeaderModule { }