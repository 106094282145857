import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map, filter, takeUntil } from 'rxjs/operators';
import { sourceSelectPipe } from 'app/core';
import { DivisionSettings, PartialTags, Populated } from 'app/shared/interfaces';
import { Options as formOptions } from 'app/shared/forms/teachers/teachers.component';
import { TeachersComponent as Form } from 'app/shared/forms/teachers/teachers.component';
import { SelectionService } from 'app/shared/services/selection/selection.service';
import { Collection } from '../types';
import { COLLECTION } from '../constants';
import { TableCore } from '../table-core';
import { TableColumnsService } from '../services/table-columns/table-columns.service';
import { CustomSearchService } from '../services/custom-search/custom-search.service';
import { StateService } from '../services/state/state.service';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';


const collection = 'teachers' satisfies Collection;
type RowType = Populated.teacher;

@Component({
    selector: 'app-teachers-table',
    templateUrl: './teachers.component.html',
    styleUrl: './teachers.component.scss',
    providers: [
        { provide: COLLECTION, useValue: collection },
        DataSourceService,
        SelectionService,
        TableColumnsService,
        CustomSearchService,
        StateService,
    ],
    standalone: false
})
export class TeachersComponent extends TableCore<typeof collection, RowType> implements AfterViewInit {

  @ViewChild(Form)
  protected form?: Form;
  protected formOptions: formOptions = {
    showIds:         false,
    showDisplayName: true
  };

  protected settings:  Observable<DivisionSettings>;
  protected locations: Observable<Populated.location[]>;

  // bulk edit values
  protected lunchBulkValue:          null | undefined | NonNullable<Populated.teacher['lunch']>;
  protected daysBulkValue:           null | undefined | NonNullable<Populated.teacher['days']>;
  protected intervalsBulkValue:      null | undefined | NonNullable<Populated.teacher['intervals']>;
  protected lockedTimesBulkValue:    null | undefined | NonNullable<Populated.teacher['lockedTimes']>;
  protected minBreakLengthBulkValue: null | undefined | NonNullable<Populated.teacher['minBreakLength']>;
  protected tagsBulkValue:           null | undefined | PartialTags;

  constructor () {
    super();
  }

  ngAfterViewInit () {
    super.ngAfterViewInit();

    this.afterSourceGroupBy(
      ['teachers', 'locations', 'settings'],
      [],
      this._source.getTeachersNew
    )
    .then(() => {
      this.dataSource.init({ source: this.data });
      this.selection.dataSource = this.dataSource;

      this.settings  = this._source.getStrictSettings    ({ did: this.did, onDestroy: this.destroyRef });
      this.locations = this._source.getPopulatedLocations({ did: this.did, onDestroy: this.destroyRef });

      this.subscribeToSelection();
    });
  }

  // subscribes to selection events
  private subscribeToSelection () {
    this._source
    .getPopulatedTeachers({ did: this.did, skipNoFilter: true, onDestroy: this.destroyRef },
      sourceSelectPipe(
        this.selection.onSelection().pipe(map(x => ({ 'id': x.map(y => y.id) })))
      ))
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      debounceTime(200)
    )
    .subscribe(xs => {
      this.lunchBulkValue          = TableCore.isSame(xs.map(x => x.lunch          ?? []), ['intervals', 'duration', 'durationVariance']);
      this.daysBulkValue           = TableCore.isSame(xs.map(x => x.days           ?? []), ['day', 'rank']);
      this.intervalsBulkValue      = TableCore.isSame(xs.map(x => x.intervals      ?? []), ['start', 'end']);
      this.lockedTimesBulkValue    = TableCore.isSame(xs.map(x => x.lockedTimes    ?? []), ['start', 'end', 'displayName']);
      this.minBreakLengthBulkValue = TableCore.isSame(xs.map(x => x.minBreakLength ?? null));
      this.tagsBulkValue           = TableCore.getSelectedTags(xs);
    });
  }
}