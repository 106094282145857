import { ElementRef, inject, Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSortable } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, fromEvent, map, switchMap, take } from 'rxjs';
import { pick, toNumber } from 'lodash';
import $ from 'jquery';
import { StorageService } from '@app/core';
import { MatPaginator, MatSort } from '@app/common';
import { COLLECTION } from '../../constants';
import { storageKeys } from './constants';
import { DIVISION_ID } from '@app/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Collection } from '../../types';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';
import { SearchComponent } from '@app/shared/components/search/search.component';



@Injectable({
  providedIn: 'root'
})
export class StateService<C extends Collection> {
  private readonly did        = inject(DIVISION_ID);
  private readonly collection = inject(COLLECTION) as C;
  private readonly dataSource = inject(DataSourceService);
  private readonly storage    = inject(StorageService);

  private readonly storageKey;
  private readonly pageSizeKey;
  private pageIndexKey (did: string) { return `${this.storageKey}/page-index/${did}`; }
  private searchKey    (did: string) { return `${this.storageKey}/search/${did}`;     }
  private scrollKey    (did: string) { return `${this.storageKey}/scroll/${did}`;     }
  private sortKey      (did: string) { return `${this.storageKey}/sort/${did}`;       }


  constructor (
  ) {
    this.storageKey  = storageKeys[this.collection];
    this.pageSizeKey = `${this.storageKey}/page-size`;

    // load search string
    this._searchComponent.pipe(
      filter(Boolean),
      takeUntilDestroyed(),
      take(1)
    )
    .subscribe(searchComponent => {
      const previousValue = sessionStorage.getItem(this.searchKey(this.did));
      if (previousValue && searchComponent.value() != previousValue) searchComponent.value.set(previousValue);
    });
    // store search string
    this._searchComponent.pipe()
    .pipe(
      filter(Boolean),
      switchMap(x => x.value$),
      takeUntilDestroyed()
    )
    .subscribe(searchString => {
      sessionStorage.setItem(this.searchKey(this.did), searchString)
    });



    // load and sync page size
    combineLatest({
      pageSize:  this.storage.value$<number>(this.pageSizeKey).pipe(filter(Boolean)),
      paginator: this._matPaginator                           .pipe(filter(Boolean))
    })
    .pipe(
      takeUntilDestroyed()
    )
    .subscribe(({ pageSize, paginator }) => {
      const previousPageIndex = paginator.pageIndex;
      const pageIndex         = Math.floor(previousPageIndex * paginator.pageSize / pageSize);

      paginator.pageSize = pageSize;

      // need to emit manually to trigger paginator.page of all components
      // (not only the one that triggered the change)
      paginator.page.emit({
        previousPageIndex, pageIndex, pageSize,
        length: paginator.length
      } as PageEvent);

    });
    // store page size
    this._matPaginator
    .pipe(
      takeUntilDestroyed(),
      filter(Boolean),
      switchMap(paginator => paginator.page),
      map(x => x.pageSize),
      distinctUntilChanged()
    )
    .subscribe(pageSize => this.storage.set(this.pageSizeKey, pageSize));



    // load page index
    this._matPaginator
    .pipe(
      filter(Boolean),
      takeUntilDestroyed(),
      take(1)
    )
    .subscribe(paginator => {
      const raw = sessionStorage.getItem(this.pageIndexKey(this.did));
      if ( ! raw) return;

      // TODO: validate the values allowing parsing to fail
      const pageIndex = toNumber(raw);

      if (pageIndex && paginator.pageIndex != pageIndex) {
        const previousPageIndex = paginator.pageIndex;

        paginator.pageIndex = pageIndex;

        // need to emit manually to trigger paginator.page
        paginator.page.emit({
          previousPageIndex, pageIndex,
          pageSize: paginator.pageSize,
          length: paginator.length
        } as PageEvent);
      }
    });
    // store page index
    this._matPaginator.pipe(
      filter(Boolean),
      switchMap(paginator => paginator.page),
      map(x => x.pageIndex),
      distinctUntilChanged(),
      takeUntilDestroyed()
    )
    .subscribe(pageIndex => {
      sessionStorage.setItem(this.pageIndexKey(this.did), pageIndex.toString());
    });



    // loading scroll position
    combineLatest({
      tableContainer: this._tableContainer.pipe(filter(Boolean)),
      finishedLoaded: this.dataSource.loading$.pipe(
        filter(loaded => ! loaded),
      )
    })
    .pipe(
      takeUntilDestroyed(),
      take(1)
    )
    .subscribe(({ tableContainer }) => {
      const raw = sessionStorage.getItem(this.scrollKey(this.did));
      if ( ! raw) return;

      // TODO: validate the values allowing parsing to fail
      const { top, left } = JSON.parse(raw) as { top: number, left: number };

      // await table rendering
      setTimeout(() => {
        if (top)  $(tableContainer.nativeElement).scrollTop(top);
        if (left) $(tableContainer.nativeElement).scrollLeft(left);
      }, 0);
    });
    // storing scroll position
    this._tableContainer.pipe(
      filter(Boolean),
      switchMap(x => fromEvent<Event>(x.nativeElement, 'scroll')),
      map(x => x.target),
      filter(Boolean),
      debounceTime(100),
      takeUntilDestroyed()
    )
    .subscribe(scrollTraget => {
      const elem = $(scrollTraget);
      const top  = elem.scrollTop();
      const left = elem.scrollLeft();
      sessionStorage.setItem(this.scrollKey(this.did), JSON.stringify({ top, left }));
    });



    // load sort
    this._matSort
    .pipe(
      filter(Boolean),
      takeUntilDestroyed(),
      take(1)
    )
    .subscribe(sort => {
      const raw = sessionStorage.getItem(this.sortKey(this.did));
      if ( ! raw) return;

      // TODO: validate the values allowing parsing to fail
      const { active, direction } = JSON.parse(raw) as { active: string, direction: string };

      // direction '' means no sort
      if (direction) sort.sort({ id: active, start: direction } as MatSortable);
    });
    // store sort
    this._matSort
    .pipe(
      filter(Boolean),
      switchMap(sort => sort.sortChange),
      map(x => JSON.stringify(pick(x, 'active', 'direction'))),
      distinctUntilChanged(),
      takeUntilDestroyed()
    )
    .subscribe(sortString => {
      sessionStorage.setItem(this.sortKey(this.did), sortString)
    });
  }


  ////
  //// IO
  ////
  set searchComponent (val: SearchComponent | undefined) { if (val) this._searchComponent.next(val); }
  private readonly _searchComponent = new BehaviorSubject<SearchComponent | null>(null);

  set matPaginator (val: MatPaginator | undefined) { if (val) this._matPaginator.next(val); }
  private readonly _matPaginator = new BehaviorSubject<MatPaginator | null>(null);

  set matSort (val: MatSort | undefined) { if (val) this._matSort.next(val); }
  private readonly _matSort = new BehaviorSubject<MatSort | null>(null);

  set tableContainer (val: ElementRef | undefined) { if (val) this._tableContainer.next(val); }
  private readonly _tableContainer = new BehaviorSubject<ElementRef<HTMLElement> | null>(null);
}