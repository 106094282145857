
import { Component,
         OnDestroy                             } from '@angular/core';
import { CdkDragDrop,
         moveItemInArray                       } from '@angular/cdk/drag-drop';
import { BehaviorSubject,
         Subject,
         takeUntil                             } from 'rxjs';

import { MatDialogRef                          } from '@app/common';
import { Collection                            } from '../../types';
import { TableColumnsService,
         TableColumnProperties                 } from '../../services/table-columns/table-columns.service';
import { chain } from 'lodash';

@Component({
    templateUrl: './edit-columns.component.html',
    styleUrls: ['./edit-columns.component.scss'],
    standalone: false
})
export class EditColumnsComponent<C extends Collection> implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  protected onColumns = new BehaviorSubject<({ name: string } & TableColumnProperties)[]>([]);

  constructor (
    private _ref:  MatDialogRef<EditColumnsComponent<C>>,
    private _cols: TableColumnsService<C>,
  ) {

    // subscribe to changes
    this._cols.onChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(columns => {
      const arr = Object.entries(columns).map(([name, x]) => ({ name, ...x }));
      this.onColumns.next(arr);
    });

    // update when closed
    this._ref.afterClosed()
    .subscribe(() => {
      // convert back to record
      const columns = chain(this.onColumns.value)
        .keyBy('name')
        .mapValues(x => x.enabled)
        .value();

      // store
      this._cols.store(columns);
    });
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  protected drop (event: CdkDragDrop<string[]>) {
    moveItemInArray(this.onColumns.value, event.previousIndex, event.currentIndex);
    this.onColumns.next(this.onColumns.value);
  }

  protected reset () {
    const arr = Object.entries(this._cols.get()).map(([name, x]) => ({ name, ...x }));
    this.onColumns.next(arr);
  }

  protected trackByFn (_: number, item: { name: string }) {
    return item.name;
  }
}
