import { Directive,
         ViewContainerRef,
         Input                                    } from '@angular/core';
import { animationFrameScheduler                  } from 'rxjs';

import { EventsComponent                          } from '../events/events.component';

@Directive({
    selector: '[eventTable]',
    standalone: false
})
export class EventTableDirective {
  static nextId:    number = 0;
  private instance: number = EventTableDirective.nextId++;

  /*
    TODO
    instantiate a singleton instance and bind that to this
    element on select ant filter with new course
    kind of this https://angular.io/guide/dynamic-component-loader#dynamic-component-loader
  */

  constructor(public viewContainerRef: ViewContainerRef,
              /*@Inject(EventsComponent) private table: EventsComponent*/) {
    /*console.log('created elem', this.instance)
    if (this.instance == 0) {
      Object.assign(table, {
        selectable:  false,
        paginator:   false,
        search:      false,
        showLoading: false,
        add:         false,
        delete:      true,
        minContent:  true
      });
    }*/
    /*
      (onSave)="onSave.emit($event)"
    */
  }

  @Input()
  get selected(): boolean { return this._selected; }
  set selected(value: boolean) {
    /*
      set course on table
    */
    if (this._selected === !!value)
      return;

    this._selected = !!value;

    if (this.selected) {
      animationFrameScheduler.schedule(() => {
        //this.table.course = this.course;
        this.viewContainerRef.clear();
        const componentRef = this.viewContainerRef.createComponent<EventsComponent>(EventsComponent);

        componentRef.instance.editable         = this.editable;
        componentRef.instance.course           = this.course;
        componentRef.instance.selectable       = false;
        componentRef.instance.paginator        = false;
        componentRef.instance.search           = false;
        componentRef.instance.showLoading      = false;
        componentRef.instance.add              = false;
        componentRef.instance.delete           = true;
        componentRef.instance.minContent       = true;
        componentRef.instance.toolbar          = false;
      })
    } else {
      this.viewContainerRef.clear();
    }
  }
  private _selected: boolean;

  @Input()
  get editable(): boolean { return this._editable; }
  set editable(value: boolean) {
    this._editable = !!value;

    /*if (this.selected)
      this.table.editable = this._editable;*/
  }
  private _editable: boolean;

  @Input()
  get course(): string { return this._course; }
  set course(value: string) {
    this._course = value;
  }
  private _course: string;

  @Input()
  get did(): string { return this._did; }
  set did(value: string) {
    this._did = value;
  }
  private _did: string;

  @Input()
  get eventTable(): string { return this._eventTable; }
  set eventTable(value: string) {
    this._eventTable = value;
  }
  private _eventTable: string;
}