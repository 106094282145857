import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map, filter, takeUntil } from 'rxjs/operators';
import { DivisionSettings, PartialTags, Populated } from 'app/shared/interfaces';
import { sourceSelectPipe } from 'app/core';
import { PersonsComponent as Form } from 'app/shared/forms/persons/persons.component';
import { SelectionService } from 'app/shared/services/selection/selection.service';
import { Collection } from '../types';
import { COLLECTION } from '../constants';
import { TableCore } from '../table-core';
import { TableColumnsService } from '../services/table-columns/table-columns.service';
import { CustomSearchService } from '../services/custom-search/custom-search.service';
import { StateService } from '../services/state/state.service';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';


const collection = 'persons' satisfies Collection;
type RowType = Populated.person;

@Component({
    selector: 'app-persons-table',
    templateUrl: './persons.component.html',
    styleUrl: './persons.component.scss',
    providers: [
        { provide: COLLECTION, useValue: collection },
        DataSourceService,
        SelectionService,
        TableColumnsService,
        CustomSearchService,
        StateService,
    ],
    standalone: false
})
export class PersonsComponent extends TableCore<typeof collection, RowType> implements AfterViewInit {


  @ViewChild(Form)
  protected form?: Form;


  protected settings:  Observable<DivisionSettings>;
  protected groups:    Observable<Populated.group[]>;
  protected locations: Observable<Populated.location[]>;

  // bulk edit values
  protected groupsBulkValue: null | undefined | NonNullable<Populated.person['group']>;
  protected sexBulkValue:    null | undefined | NonNullable<Populated.person['sex']>;
  protected tagsBulkValue:   null | undefined | PartialTags;

  constructor () {
    super();
  }

  ngAfterViewInit () {
    super.ngAfterViewInit();

    this.afterSourceGroupBy(
      ['persons', 'groups', 'settings'],
      [],
      this._source.getStudentsNew
    )
    .then(() => {
      this.dataSource.init({ source: this.data });
      this.selection.dataSource = this.dataSource;

      this.settings  = this._source.getStrictSettings    ({ did: this.did, onDestroy: this.onDestroy });
      // this.groups    = this.source.getPopulatedGroups   ({ did: this.did }, sourceHasPipe({ species: true }));
      this.locations = this._source.getPopulatedLocations({ did: this.did, onDestroy: this.onDestroy });

      this.subscribeToSelection();
    })
  }

  // subscribes to selection events
  private subscribeToSelection () {
    this._source
    .getPopulatedStudents({ did: this.did, skipNoFilter: true, onDestroy: this.onDestroy },
      sourceSelectPipe(
        this.selection.onSelection().pipe(map(x => ({ 'id': x.map(y => y.id) })))
      ))
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      debounceTime(200)
    )
    .subscribe(xs => {
      this.groupsBulkValue = TableCore.isSame(xs.map(x => x.group ?? null));
      this.sexBulkValue    = TableCore.isSame(xs.map(x => x.sex   ?? null));
      this.tagsBulkValue   = TableCore.getSelectedTags(xs);
    });
  }
}
