<span mat-dialog-title>
  {{ 'common.custom_search' | translate }}
</span>

<mat-dialog-content>
  <mat-selection-list [formControl]="ctrl">
    @for (x of options | async; track $index) {
      <mat-list-option [value]="x">
        <span [innerHtml]="names[x] | translate | capitalizeFirst"></span>
      </mat-list-option>
    }
  </mat-selection-list>
  <mat-divider></mat-divider>
  <mat-selection-list [formControl]="allCtrl">
    <mat-list-option [value]="true">
      {{ 'common.toggle_all' | translate }}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
  @if (onNone | async) {
    <mat-icon class="warning" [matTooltip]="'tables.components.custom-search.empty' | translate">
      warning
    </mat-icon>
  }
  <span class="spacer"></span>
  <button mat-button mat-dialog-close cdkFocusInitial>{{ 'common.close' | translate }}</button>
</mat-dialog-actions>
