import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';
import { NgPipesModule                           } from 'ngx-pipes';

import { TranslationModule                       } from 'app/core/translate/translate.module';
import { SharedPipesModule                             } from 'app/shared/pipes/pipes.module';
import { TooltipModule                           } from 'app/shared/directives/tooltip/tooltip.module';
import { SharedFormsModule      as AppFormsModule      } from 'app/shared/forms/forms.module';
import { SharedComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';

import { ComponentsModule                        } from '../components/components.module';
import { CoursesComponent                        } from './courses.component';
import { EventTooltipPipe,
         RemoveSelfPipe,
         RestrictSetsPipe,
         GetPipe,
         PlannedDurationIsSatisfiedPipe          } from './courses.pipe';
import { EventTableDirective                     } from './courses.directive';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { SharedDirectivesModule } from '@app/shared/directives/directives.module';
import { CustomSearchComponent } from '../components/custom-search/custom-search.component';
import { HasAppFeaturePipe } from "../../../../core/environment/environment.pipe";
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { FormFieldsDirective } from '@app/shared/form-fields/form-fields.directive';
import { BreakLengthComponent } from '@app/shared/form-fields/break-length/break-length.component';
import { DisplayValueComponent as BreakLengthDisplayValueComponent } from '@app/shared/form-fields/break-length/components/display-value/display-value.component';
import { AvailableDaysComponent } from '@app/shared/form-fields/available-days/available-days.component';
import { DisplayValueComponent as AvailableDaysDisplayValueComponent } from '@app/shared/form-fields/available-days/components/display-value/display-value.component';
import { PeriodComponent } from '@app/shared/form-fields/period/period.component';
import { DisplayValueComponent as PeriodDisplayValueComponent } from '@app/shared/form-fields/period/components/display-value/display-value.component';
import { AvailableLocationsComponent } from '@app/shared/form-fields/available-locations/available-locations.component';
import { CenterOfAttractionComponent } from '@app/shared/form-fields/center-of-attraction/center-of-attraction.component';
import { DisplayValueComponent as CenterOfAttractionDisplayValueComponent } from '@app/shared/form-fields/center-of-attraction/components/display-value/display-value.component';
import { ColorComponent } from '@app/shared/form-fields/color/color.component';
import { DisplayValueComponent as ColorDisplayValueComponent } from '@app/shared/form-fields/color/components/display-value/display-value.component';
import { IntervalsComponent } from '@app/shared/form-fields/intervals/intervals.component';
import { DisplayValueComponent as IntervalsDisplayValueComponent } from '@app/shared/form-fields/intervals/components/display-value/display-value.component';
import { LockedTimesComponent } from '@app/shared/form-fields/locked-times/locked-times.component';
import { DisplayValueComponent as LockedTimesDisplayValueComponent } from '@app/shared/form-fields/locked-times/components/display-value/display-value.component';
import { TagsComponent } from '@app/shared/form-fields/tags/tags.component';
import { DisplayValueComponent as TagsDisplayValueComponent } from '@app/shared/form-fields/tags/components/display-value/display-value.component';
import { TextComponent } from '@app/shared/form-fields/text/text.component';
import { PlannedDurationComponent } from '@app/shared/form-fields/planned-duration/planned-duration.component';
import { SearchComponent } from '@app/shared/components/search/search.component';
import { TypeSafeMatCellDefDirective } from '@app/shared/directives/type-safe-mat-cell-def/type-safe-mat-cell-def.directive';

@NgModule({
  declarations: [
    CoursesComponent,
    RemoveSelfPipe,
    RestrictSetsPipe,
    EventTooltipPipe,
    GetPipe,
    PlannedDurationIsSatisfiedPipe,
    EventTableDirective
  ],
  exports: [
    CoursesComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatMenuModule,
    SharedDirectivesModule,
    NgPipesModule,
    TranslationModule,
    SharedPipesModule,
    TooltipModule,
    AppFormsModule,
    AppComponentsModule,
    ComponentsModule,
    SearchComponent,
    CustomSearchComponent,
    HasAppFeaturePipe,
    LoadingComponent,
    FormFieldsDirective,
    TextComponent,
    PlannedDurationComponent,
    BreakLengthComponent, BreakLengthDisplayValueComponent,
    AvailableDaysComponent, AvailableDaysDisplayValueComponent,
    PeriodComponent, PeriodDisplayValueComponent,
    AvailableLocationsComponent,
    CenterOfAttractionComponent, CenterOfAttractionDisplayValueComponent,
    ColorComponent, ColorDisplayValueComponent,
    IntervalsComponent, IntervalsDisplayValueComponent,
    LockedTimesComponent, LockedTimesDisplayValueComponent,
    TagsComponent, TagsDisplayValueComponent,
    TypeSafeMatCellDefDirective
]
})
export class CoursesModule { }
