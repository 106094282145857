import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { SharedPipesModule                     } from 'app/shared/pipes/pipes.module';
import { CreateComponent                 } from './create.component';

@NgModule({
  declarations: [
    CreateComponent,
  ],
  exports: [
    CreateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPipesModule,
    AppCommonModule,
    TranslationModule,
  ]
})
export class CreateModule { }
