import { Pipe,
         PipeTransform                   } from '@angular/core';

import { TranslateService                } from 'app/core';
import { Populated as P                  } from 'app/shared/interfaces';
import { Util                            } from '@app/common';
import { compact, sum as _sum } from 'lodash';

@Pipe({
    name: 'removeSelf',
    standalone: false
})
export class RemoveSelfPipe implements PipeTransform {
  transform(coalesced: P.overlapGroup["coalesced"] | undefined, self: P.course) {
    return coalesced?.filter(x => x.to?.id != self.id);
  }
}

@Pipe({
    name: 'eventTooltip',
    standalone: false
})
export class EventTooltipPipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform(course: P.course, event: P.event, events?: P.event[]): string {
    const name:     string = event?.displayName ?? course.displayName ?? course.ids!;
    const sum:      number = _sum(compact(compact(events)?.map(x => x.preferredDuration)) ?? []);

    const duration: string = `${ event?.preferredDuration } ${ this._translate.instant('common.minutes') }`;
    const total: string = `${ this._translate.instant('common.total') } ${ sum }  ${ this._translate.instant('common.minutes') }`;
    return `${ name } \n\n ${ duration } \n\n ${ total }`;
  }
}

@Pipe({
    name: 'restrictSets',
    standalone: false
})
export class RestrictSetsPipe implements PipeTransform {

  transform (
    durationSets: number[][],
    course:       P.course,
    numWeeks:     number | undefined,
  ): { val: number[], sum: number, warning: boolean }[] {

    // compute planned minutes per week
    let plannedMinutesPerWeek: number | null = null;
    const [value, unit] = Util.functions.extractAndParsePlannedDuration(course) ?? [null, null];
    if (unit == 'min/week') {
      plannedMinutesPerWeek = value;
    } else if (unit == 'hrs' && numWeeks != null) {
      plannedMinutesPerWeek = value * 60 / numWeeks;
      plannedMinutesPerWeek = Math.ceil(plannedMinutesPerWeek / 5) * 5;
    }

    return durationSets.map(x => {
      const sum = _sum(x);

      return {
        val:     x,
        sum:     sum,
        warning: plannedMinutesPerWeek != null && sum > plannedMinutesPerWeek
      }
    });
  }
}

@Pipe({
    name: 'get',
    standalone: false
})
export class GetPipe implements PipeTransform {
  transform <T> (map: Map<T, any> | undefined | null, key: T): any {
    return map?.get(key);
  }
}

@Pipe({
    name: 'plannedDurationIsSatisfied',
    standalone: false
})
export class PlannedDurationIsSatisfiedPipe implements PipeTransform {
  transform (course: P.course, numWeeks?: number): boolean {
    // extract and parse planned duration
    const plannedDuration = Util.functions.extractAndParsePlannedDuration(course);
    if ( ! plannedDuration) return true;
    const [value, unit] = plannedDuration;

    // actual minutes per week of the course
    const actualMinutes = _sum(course.events?.map(x => x.preferredDuration));

    if (unit == 'min/week') {
      // if already in min/week, simply compare
      return actualMinutes >= value;
    } else if (numWeeks != null) {
      // convert to min/week (round up) and compare
      let plannedMinutes = value * 60 / numWeeks;
      plannedMinutes = Math.ceil(plannedMinutes / 5) * 5;
      return actualMinutes >= plannedMinutes;
    }

    return true;
  }
}