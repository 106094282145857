
import { Component,
         Inject                                } from '@angular/core';
import { FormControl,
         ReactiveFormsModule                   } from '@angular/forms';
import { takeUntilDestroyed                    } from '@angular/core/rxjs-interop';
import { BehaviorSubject                       } from 'rxjs';
import { Util                                  } from '@app/common';
import { Collection                            } from '../../types';
import { COLLECTION                            } from '../../constants';
import { CustomSearchService,
         FormValue,
         FromControlLabels                     } from '../../services/custom-search/custom-search.service';
import { CommonModule } from '@angular/common';
import { TranslationModule } from '@app/core/translate/translate.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { SharedPipesModule } from '@app/shared/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';


type Key<C extends Collection>  = keyof FormValue[C];
type Keys<C extends Collection> = Key<C>[];

@Component({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatTooltipModule,
        SharedPipesModule,
        TranslationModule
    ],
    templateUrl: './custom-search.component.html',
    styleUrl: './custom-search.component.scss'
})
export class CustomSearchComponent<C extends Collection> {
  protected readonly names: Record<Key<C>, string>;

  protected readonly options = new BehaviorSubject<Keys<C>>([]);
  protected readonly ctrl    = new FormControl<Keys<C>>([], { nonNullable: true });
  protected readonly onNone  = new BehaviorSubject<boolean | null>(null);

  protected readonly allCtrl = new FormControl<true[]>([]);

  constructor (
    @Inject(COLLECTION) private collection: C,
    private _custom: CustomSearchService<C>
  ) {
    this.names = FromControlLabels[this.collection] as Record<Key<C>, string>;

    this.allCtrl.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe(x => {
      if (x?.at(0)) {
        this.ctrl.setValue(this.options.value, { emitEvent: true });
      } else {
        this.ctrl.setValue([], { emitEvent: true });
      }
    });

    this._custom.onValue()
    .pipe(takeUntilDestroyed())
    .subscribe(record => {
      // the emitted value already takes into account visible and hidden columns
      this.options.next(Util.functions.objectKeys(record));

      // update form value
      const trueKeys = Util.functions.objectKeyVals(record).filter(x => x.val).map(x => x.key);
      this.ctrl.setValue(trueKeys, { emitEvent: false });

      // wether all or none are selected
      const all = trueKeys.length === Object.keys(record).length;
      const allSelected = !! this.allCtrl.value?.at(0);
      if (all !== allSelected) {
        this.allCtrl.setValue(all ? [true] : [], { emitEvent: false });
      }

      this.onNone.next(trueKeys.length === 0);
    });

    // store the form value
    this.ctrl.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe(x => {
      // we need to include all keys with a falsy/truthy value
      const record = Object.fromEntries(this.options.value.map(x => [x, false])) as Partial<FormValue[C]>;
      x.forEach(key => record[key] = true as FormValue[C][Key<C>]);
      this._custom.store(record);
    });
  }

}