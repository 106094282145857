import { inject } from "@angular/core";
import { COURSE_TABLE_COLUMNS } from "../constants";
import { EnvironmentService, UserPreferencesService } from "@app/core";
import { filter, map, Observable } from "rxjs";
import { Columns } from "../services/table-columns/types";
import { eventsColumns } from "../services/table-columns/constants";
import { Util } from "@app/common";


export function tableColumnsOverride$ (): Observable<Columns['events']> | undefined {
  // only injected when opened from within the courses table
  const courseTableColumns = inject(COURSE_TABLE_COLUMNS, { optional: true });
  const environment        = inject(EnvironmentService);
  const preferences        = inject(UserPreferencesService);

  return courseTableColumns?.watch.pipe(
    filter(Boolean),
    map(x => {

      // fetch event columns and enable all of them
      const columns = eventsColumns(environment, preferences.displayPublicId)
      Object.values(columns).forEach(x => x.enabled = true);

      // start from empty object
      const res: Columns['events'] = {
        // should never be shown: course, subject

        // should always be shown
        preferredDuration: columns.preferredDuration,
        fixedStart:        columns.fixedStart
      };

      // order according to the course columns
      Util.functions.objectEntries(x).forEach(([key, value]) => {
        // ignore all hidden columns
        if ( ! value.enabled) return;

        // identical names
        if (key === 'displayName')        res.displayName        = columns.displayName;
        if (key === 'groups')             res.groups             = columns.groups;
        if (key === 'teachers')           res.teachers           = columns.teachers;
        if (key === 'locations')          res.locations          = columns.locations;
        if (key === 'days')               res.days               = columns.days;
        if (key === 'intervals')          res.intervals          = columns.intervals;
        if (key === 'period')             res.period             = columns.period;
        if (key === 'centerOfAttraction') res.centerOfAttraction = columns.centerOfAttraction;
        if (key === 'lockedTimes')        res.lockedTimes        = columns.lockedTimes;
        if (key === 'minBreakLength')     res.minBreakLength     = columns.minBreakLength;
        if (key === 'color')              res.color              = columns.color;
        if (key === 'ids')                res.ids                = columns.ids;
        if (key === 'tags')               res.tags               = columns.tags;

        // have slightly different names
        if (key === 'eventDurationVariance') res.durationVariance = columns.durationVariance;
        if (key === 'overlapGroup')          res.overlapSpecies   = columns.overlapSpecies;
      });

      return res;
    })
  );
}
