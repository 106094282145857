import { inject, Injectable } from '@angular/core';
import { combineLatest, debounceTime, filter, map, Observable, startWith } from 'rxjs';
import { chain } from 'lodash';
import { Util } from 'app/common';
import { StorageService } from 'app/core';
import { Collection } from '../../types';
import { COLLECTION } from '../../constants';
import { Columns, TableColumnsService } from '../table-columns/table-columns.service';
import { FormValue } from './types';
import { defaultFormValue, formValueToSearchKeys, storageKeys } from './constants';

export * from './types';
export * from './constants';


type CollectionData<C extends Collection> = {
  collection: C;
  value:      Partial<FormValue[C]>;
  columns:    Columns[C];
}


@Injectable({
  providedIn: 'root'
})
export class CustomSearchService<C extends Collection> {
  private readonly collection = inject(COLLECTION) as C;
  private readonly _storage   = inject(StorageService);
  private readonly _columns   = inject<TableColumnsService<C>>(TableColumnsService);

  constructor () {
  }

  public onValue (): Observable<Partial<FormValue[C]>> {
    const onValue = this._storage.value$<Partial<FormValue[C]> >(storageKeys[this.collection])
    .pipe(
      startWith(defaultFormValue[this.collection]),
      filter(Boolean),
      debounceTime(0),
      // override the default values since wee need to ensure that all keys are present
      map(x => Object.assign({...defaultFormValue[this.collection]}, x) as FormValue[C]),
    );

    return combineLatest({
      value:   onValue,
      columns: this._columns.onChange,
    })
    .pipe(
      map(({ value, columns }) => {

        // sort the values according to the order of the columns
        const order = Object.keys(columns);
        const sorted = chain(value)
          .entries()
          .sortBy(([key, _]) => {
            const i = order.indexOf(key)
            return i === -1 ? Infinity : i;
          })
          .fromPairs()
          .value() as any as FormValue[C];

        // remove search keys for columns that are not visible
        const x: CollectionData<C> = { collection: this.collection, value: sorted, columns };
        if (this.isLocationsData(x)) {
          if ( ! x.columns.displayName?.enabled) delete x.value.displayName;
          if ( ! x.columns.ids        ?.enabled) delete x.value.ids;
          if ( ! x.columns.tags       ?.enabled) delete x.value.tags;

          let xxx: keyof typeof x.value;
              //^?

          return x.value;
        }
        else if (this.isPersonsData(x)) {
          if ( ! x.columns.firstName   ?.enabled) delete x.value.firstName;
          if ( ! x.columns.lastName    ?.enabled) delete x.value.lastName;
          if ( ! x.columns.ids         ?.enabled) delete x.value.ids;
          if ( ! x.columns.tags        ?.enabled) delete x.value.tags;
          if ( ! x.columns.SSN         ?.enabled) delete x.value.SSN;
          if ( ! x.columns.emails      ?.enabled) delete x.value.emails;
          if ( ! x.columns.phoneNumbers?.enabled) delete x.value.phoneNumbers;
          if ( ! x.columns.group       ?.enabled) delete x.value.group;

          let xxx: keyof typeof x.value;
              //^?

          return x.value;
        }
        else if (this.isTeachersData(x)) {
          if ( ! x.columns.displayName?.enabled) delete x.value.displayName;
          if ( ! x.columns.ids        ?.enabled) delete x.value.ids;
          if ( ! x.columns.tags       ?.enabled) delete x.value.tags;

          let xxx: keyof typeof x.value;
              //^?

          return x.value;
        }
        else if (this.isGroupsData(x)) {
          if ( ! x.columns.displayName?.enabled) delete x.value.displayName;
          if ( ! x.columns.ids        ?.enabled) delete x.value.ids;
          if ( ! x.columns.tags       ?.enabled) delete x.value.tags;
          if ( ! x.columns.members    ?.enabled) delete x.value.members;

          let xxx: keyof typeof x.value;
              //^?

          return x.value;
        }
        else if (this.isCoursesData(x)) {
          if ( ! x.columns.displayName          ?.enabled) delete x.value.displayName;
          if ( ! x.columns.ids                  ?.enabled) delete x.value.ids;
          if ( ! x.columns.tags                 ?.enabled) delete x.value.tags;
          if ( ! x.columns.subject              ?.enabled) delete x.value.subject;
          if ( ! x.columns.plannedDuration      ?.enabled) delete x.value.plannedDuration;
          if ( ! x.columns.eventDurationVariance?.enabled) delete x.value.eventDurationVariation;
          if ( ! x.columns.groups               ?.enabled) delete x.value.groups;
          if ( ! x.columns.teachers             ?.enabled) delete x.value.teachers;
          if ( ! x.columns.events               ?.enabled) delete x.value.preferredDuration;

          let xxx: keyof typeof x.value;
              //^?

          return x.value;
        }
        else if (this.isEventsData(x)) {

          if ( ! x.columns.displayName      ?.enabled) delete x.value.displayName;
          if ( ! x.columns.ids              ?.enabled) delete x.value.ids;
          if ( ! x.columns.tags             ?.enabled) delete x.value.tags;
          if ( ! x.columns.preferredDuration?.enabled) delete x.value.preferredDuration;
          if ( ! x.columns.groups           ?.enabled) delete x.value.groups;
          if ( ! x.columns.teachers         ?.enabled) delete x.value.teachers;
          if ( ! x.columns.course           ?.enabled) delete x.value.course;
          if ( ! x.columns.subject          ?.enabled) delete x.value.subject;


          let xxx: keyof typeof x.value;
              //^?

          return x.value;
        }

        // should not reach here
        throw new Error(`Invalid collection: ${this.collection}`);
      })
    );
  }

  public store (value: Partial<FormValue[C]>) {
    // add the new value on top of the already existing value in order to keep the stored value corresponding to the hidden columns
    const oldValue = defaultFormValue[this.collection];
    const newValue = Object.assign(oldValue, value);

    // remove all keys that do not belong to the collection
    // (in case key names change between versions)
    Util.functions.objectKeys(newValue).forEach(key => {
      if ( ! (key in defaultFormValue[this.collection])) delete newValue[key];
    });

    this._storage.set(storageKeys[this.collection], newValue);
  }

  /** @description is default if all visible values are the same as the default values */
  public onIsDefaultValue (): Observable<boolean> {
    return this.onValue().pipe(
      map(val => {
        const defaultVal = defaultFormValue[this.collection];
        return Util.functions.objectKeyVals(val).every(x => defaultVal[x.key] = x.val);
      })
    )
  }


  public onSearchKeys (): Observable<string[]> {
    return this.onValue().pipe(map(x => formValueToSearchKeys(this.collection, x)));
  }

  // type guards
  private isLocationsData(x: CollectionData<Collection>): x is CollectionData<'locations'>{ return x.collection == 'locations';}
  private isPersonsData  (x: CollectionData<Collection>): x is CollectionData<'persons'>  { return x.collection == 'persons';  }
  private isTeachersData (x: CollectionData<Collection>): x is CollectionData<'teachers'> { return x.collection == 'teachers'; }
  private isGroupsData   (x: CollectionData<Collection>): x is CollectionData<'groups'>   { return x.collection == 'groups';   }
  private isCoursesData  (x: CollectionData<Collection>): x is CollectionData<'courses'>  { return x.collection == 'courses';  }
  private isEventsData   (x: CollectionData<Collection>): x is CollectionData<'events'>   { return x.collection == 'events';   }


}
