import { Input, Directive, OnDestroy, ViewChild, Output, EventEmitter, ViewContainerRef, AfterViewInit, ElementRef, inject, DestroyRef } from '@angular/core';
import { coerceBooleanProperty, coerceNumberProperty, coerceArray } from '@angular/cdk/coercion';
import { MatPaginator } from 'app/common';
import { MatSort } from '@angular/material/sort';
import { chain, isArray, isEqual, isObject, omit, pick, uniq, uniqBy }  from 'lodash';
import { BehaviorSubject, Observable, Subject, delay, filter, map, of, switchMap, take, takeUntil } from 'rxjs';
import { EnvironmentService, SourceService, UserPreferencesService, sourceMatchPipe, sourceSelectPipe } from 'app/core';
import { Populated, PartialTags, Tags } from 'app/shared/interfaces';
import { MatDialog, Util } from 'app/common';
import { Collection, Entity } from './types';
import { Columns, TableColumnProperties } from './services/table-columns/types';
import { StateService } from './services/state/state.service';
import { TableColumnsService } from './services/table-columns/table-columns.service';
import { CustomSearchService } from './services/custom-search/custom-search.service';
import { CreateComponent, Data as CreateComponentData } from './components/create/create.component';
import { EditColumnsComponent } from './components/edit-columns/edit-columns.component';
import { CustomSearchComponent } from './components/custom-search/custom-search.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DIVISION_ID } from '@app/constants';
import { COLLECTION } from './constants';
import { appFeatures } from '@app/core/environment/environment.constants';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';
import { SelectionService } from '@app/shared/services/selection/selection.service';
import { DialogsService } from '@app/shared/services/dialogs/dialogs.service';
import { QueryService } from '@app/shared/services/query/query.service';
import { SearchComponent } from '@app/shared/components/search/search.component';


type Args = Parameters<SourceService['getPopulatedCourses']>


type Options<C extends Collection> = {
  /**
   * @description Rather than using "TableColumnService", this observable will determine what columns are shown
   */
  tableColumnsOverride$?: Observable<Columns[C]>;
};

// the lunch field is little special apparently
type NullablePartial<T> = {
  [P in keyof T]?: P extends 'lunch'
    ? Partial<Populated.lockedTime>[] | null
    : T[P] | null;
};

@Directive({
    host: {
        'class': 'app-table-component'
    },
    standalone: false
})
export abstract class TableCore<C extends Collection, T extends Entity> implements OnDestroy, AfterViewInit {
  protected readonly destroyRef        = inject(DestroyRef);
  protected readonly collection        = inject(COLLECTION);
  protected readonly did               = inject(DIVISION_ID);
  protected readonly dataSource        = inject<DataSourceService<T>>(DataSourceService);
  protected readonly selection         = inject<SelectionService<T>>(SelectionService);
  protected readonly preferences       = inject(UserPreferencesService);
  protected readonly _viewContainerRef = inject(ViewContainerRef);
  protected readonly _source           = inject(SourceService);
  protected readonly _dialog           = inject(DialogsService);
  protected readonly _matDialog        = inject(MatDialog);
  protected readonly _state            = inject<StateService<C>>(StateService);
  protected readonly _tableColumns     = inject<TableColumnsService<C>>(TableColumnsService);
  protected readonly _customSearch     = inject<CustomSearchService<C>>(CustomSearchService);
  protected readonly _environment      = inject(EnvironmentService);
  protected readonly _query            = inject(QueryService);

  protected readonly appFeatures = appFeatures;


  protected readonly onDestroy = new Subject<void>();

  @ViewChild(MatPaginator,    { static: false, read: MatPaginator })
  protected readonly paginatorComponent?: MatPaginator;
  @ViewChild(MatSort,         { static: true, read: MatSort })
  protected readonly sortComponent?:      MatSort;
  @ViewChild(SearchComponent, { static: false, read: SearchComponent })
  protected readonly searchComponent?:    SearchComponent;
  @ViewChild('tableContainer', { static: false, read: ElementRef<HTMLElement> })
  protected readonly tableContainer?:    ElementRef<HTMLElement>;

  @Output() select = new EventEmitter<T>();
  @Output() onSave = new EventEmitter<boolean>();

  protected showForm = false;

  protected data = new BehaviorSubject<{ docs: T[], totalDocs: number } | null>(null);

  protected onNoData      = new BehaviorSubject<boolean>(true);
  protected noDataColumns = new BehaviorSubject<boolean>(false);

  protected isDefaultSearchParameters = new BehaviorSubject<boolean>(true);

  // contains all unique tags of the collection
  protected tags$ = new BehaviorSubject<Tags>([]);

  constructor (opts: Options<C> = { }) {

    // subscribe to columns
    const columns$ = (opts.tableColumnsOverride$ ?? this._tableColumns.watch);
    columns$.pipe(
      filter(Boolean),
      takeUntilDestroyed()
    )
    .subscribe(x => this.setColumns(x));

    // find out if the current search parameters are the default ones
    this._customSearch.onIsDefaultValue()
    .pipe(takeUntilDestroyed())
    .subscribe(x => this.isDefaultSearchParameters.next(x));

    // expose to cypress
    if ('Cypress' in window && window.Cypress) {
      (window as unknown as Record<'EditColumns', unknown>)['EditColumns'] = this.openEditColumnsDialog.bind(this);
    }
  }

  ngAfterViewInit() {
    // pass components to table state service once the data is loaded and the table ready for interaction
    const loaded$ = new BehaviorSubject(false);
    this.dataSource.loading$
    .pipe(
      filter(x => ! x),
      take(1),
      delay(0),   // needed for some reason... (the pipeline is probably not perfectly reactive)
      takeUntilDestroyed(this.destroyRef)
    )
    .subscribe(() => {
      this._state.searchComponent = this.searchComponent;
      this._state.matPaginator    = this.paginatorComponent;
      this._state.tableContainer  = this.tableContainer;
      this._state.matSort         = this.sortComponent;

      loaded$.next(true);
    });

    // when the table is fully initialized, check if there is a search query and apply it
    loaded$
    .pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this._query.query$),
      map(x => x.search),
      filter(Boolean),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(searchStr => {
      // set the search value before removing it
      if (this.searchComponent) this.searchComponent.value.set(searchStr);
      this._query.unset('search', true);  // replaceUrl=true to not store the temporary search value in the url, thus enabling "back" to return to the previous page
    });

    // when searching always return to the first page
    this.searchComponent?.value$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {
      if ( ! this.paginatorComponent) return;

      this.paginatorComponent.pageIndex = 0;
      // need to emit manually to trigger paginator.page
      this.paginatorComponent.page.emit({
        pageIndex: this.paginatorComponent.pageIndex,
        pageSize:  this.paginatorComponent.pageSize,
        length:    this.paginatorComponent.length
      });
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  protected async afterSourceGroupBy (
    collections:          Util.Types.Collection[],
    coalescedCollections: Util.Types.Collection[],
    sourceFun:            (...[_options, ...observers]: Args) => Observable<T[]>,
  ) {
    try {
      await this._source.groupBy({ collections: collections, did: this.did });
    } catch (err) {
      console.error(err);
    }

    // data source
    (sourceFun.bind(this._source)({
        did:        this.did,
        coalesced:  coalescedCollections,
        paginator:  this.paginatorComponent,   // move to DataSourceService in order for the selection to update when deleting entities
        sort:       this.sortComponent,        // move to DataSourceService in order for the selection to update when deleting entities
      },
      ...[
        this.collection == 'events' && this.course ? sourceSelectPipe({ 'course.id': this.course })                                  : null,
        this.collection == 'persons'               ? of({ action: 'oneOf', key: 'type', value: [[null, undefined, 'Student']] })     : null,
        this.searchComponent                       ? sourceMatchPipe(this.searchComponent.value$, this._customSearch.onSearchKeys()) : null,
      ].filter(Boolean)
    ) as ReturnType<typeof sourceFun>)
    .pipe(takeUntil(this.onDestroy))
    .subscribe((x: T[] | { docs: T[], totalDocs: number }) => {
      // ensure that the format is correct
      if (isArray(x)) this.data.next({ docs: x, totalDocs: x.length });
      else            this.data.next(x);
    });

    (sourceFun.bind(this._source)({ did: this.did }) as ReturnType<typeof sourceFun>)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(x => {
      // displays no data message
      this.onNoData.next(x.length == 0);

      // get deep unique ones and sort by value
      const tags = uniqBy(x.flatMap(x => x.tags).filter(Boolean), x => `${x.type}.${x.value}`);
      tags.sort((a, b) => a.value.localeCompare(b.value));
      this.tags$.next(tags);
    });
  }

  // determines if values of multiple selected items are the same
  // if they are not we return undefined
  public static isSame<T1 = any> (
    vals:  T1[],
    keys?: (T1 extends Array<any> ? keyof T1[number] : keyof T1)[]
  ): T1 | null | undefined {
    if (vals.length == 0) return undefined;

    if (vals.length == 1) return vals[0];

    const x0: T1[]  = isArray(vals[0]) ? vals[0] : [vals[0]];

    for (let i = 1; i < vals.length; i++) {
      const xi: T1[] = isArray(vals[i]) ? vals[i] as T1[] : [vals[i]];

      if (keys) {
        const sanitizedBase = x0.map(x => pick(x, keys));
        if (! isEqual(xi.map(x => pick(x, keys)), sanitizedBase)) return undefined;
      } else {
        if ( ! isEqual(x0, xi)) return undefined;
      }

    }

    const val = vals[0];
    return Array.isArray(val) ?
      val.map(x => isObject(x) ? (keys?.includes('id' as (typeof keys)[0]) ? x : omit(x, 'id')) : x) as T1 :
      (
        isObject(val) ?
          (keys?.includes('id' as (typeof keys)[0]) ? val : omit(val, 'id')) :
          val
      ) as T1;
  }

  public static getSelectedTags (selected: { tags?: Tags }[]): PartialTags {
    const numSelected = selected.length;
    return chain(selected)
      .flatMap(x => x.tags)
      .filter(Boolean)
      .groupBy(x => `${encodeURIComponent(x?.type ?? '')}/${encodeURIComponent(x.value)}`)
      .map(arr => {
        // make partial if not all selected rows have the tag
        const partial = arr.length < numSelected ? true : false;
        return { ...arr[0], ...partial && { partial } };
      })
      .value();
  }

  protected trackBy(index: number, elem: T) {
    return elem.id ?? index;
  }

  protected set columns (val) {
    this._columns     = uniq(val);
    this._bulkColumns = this.columns.map(x => 'bulk-' + x)
  }
  protected get columns                          () { return this._columns;                          }
  protected get bulkColumns                      () { return this._bulkColumns;                      }
  protected get columnTitleTranslationKeys       () { return this._columnTitleTranslationKeys;       }
  protected get columnDescriptionTranslationKeys () { return this._columnDescriptionTranslationKeys; }
  private _columns                          = new Array<string>();
  private _bulkColumns                      = new Array<string>();
  private _columnTitleTranslationKeys:       Record<string, string> = {};
  private _columnDescriptionTranslationKeys: Record<string, string> = {};


  protected setColumns (
    val: Record<string, TableColumnProperties>
  ) {
    // remove nonactive columns and further perform custom search
    const columns = [...Object.entries(val)]
      .map(([key, value]) => ({ name: key, ...value }))
      .filter(x => x.enabled);

    this.noDataColumns.next(columns.length == 0);

    // add special columns
    const columnNames = [
      this.selectable ? ['select'] : [],
      columns.map(x => x.name),
      ['absorber'], // this cell will absorb all remaining space of the table and fix the stickyEnd bug (covers the sticky end columns which normally are displayed above the header column when scrolled)
      this._delete ? ['actions'] : [],
    ].flat();

    this._columns                          = columnNames;
    this._bulkColumns                      = columnNames.map(x => 'bulk-' + x);
    this._columnTitleTranslationKeys       = Object.fromEntries(columns.map(x => ([x.name, x.title      ])));
    this._columnDescriptionTranslationKeys = Object.fromEntries(columns.map(x => ([x.name, x.description])));
  }


  ////
  //// source operations
  ////
  protected create(model?: Partial<T>): void {
    if ( ! model) return;
    this._source.set({ collection: this.collection, did: this.did }, { ...model });
  }

  protected edit (id: string, change: NullablePartial<T>): void {
    this.onSave.emit(true);
    this._source.set({ collection: this.collection, did: this.did }, { ...change, id });
  }

  protected editMany (ids: string[], change: NullablePartial<T>): void {
    const update = ids?.map((id: string) => ({ ...change, id }));
    this._source.set({ collection: this.collection, did: this.did }, update);
  }

  protected bulkUpdateTags (
    selected: { id: string; tags?: Tags }[],
    tags:     PartialTags | undefined | null
  ): void {
    // remove any other properties than id and tags
    selected = selected.map(x => pick(x, 'id', 'tags'));

    // find differences between previous and next tags to figure out which ta add and remove
    const prev = TableCore.getSelectedTags(selected);
    const next = tags ?? [];
    const removed: Tags = prev.filter(x => ! next.find(y => isEqual(x, y))).map(x => omit(x, 'partial'));
    const added:   Tags = next.filter(x => ! prev.find(y => isEqual(x, y))).map(x => omit(x, 'partial'));

    // update the tags accordingly
    selected.forEach(x => x.tags = (x.tags ?? [])
      .filter(x => ! removed.find(y => x.type == y.type && x.value == y.value))  // to ensure that "partial" is not compared
      .concat(added)
    );

    // update
    this._source.set({ collection: this.collection, did: this.did }, selected);
  }

  protected copy (element: Util.Types.Nullable<Partial<T> >, ...properties: string[]): void {
    this._source.set({ collection: this.collection, did: this.did }, pick(omit(element, 'id'), properties));
  }

  protected deleteOne (id: string): void {
    this._dialog.openRemoveDialog()
    .subscribe(confirmed => {
      if (! confirmed) return;
      this._source.unset({ collection: this.collection, did: this.did }, id);
    });
  }

  protected deleteMany (id: string[]): void {
    this._dialog.openRemoveDialog()
    .subscribe(confirmed => {
      if ( ! confirmed) return;
      this._source.unset({ collection: this.collection, did: this.did }, id);
      this.selection.clear();
    });
  }

  protected openCreateEntityDialog () {
    this._matDialog.open<CreateComponent, CreateComponentData>(CreateComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['responsive-dialog'],
      minWidth:     '480px',
      width:        '90vw',
      maxWidth:     '99vw',
      data: {

        did: this.did
      }
    });
  }

  protected openEditColumnsDialog () {
    this._matDialog.open<EditColumnsComponent<C>>(EditColumnsComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['no-padding', 'overflow-hidden']
    });
  }

  protected openCustomSearchDialog () {
    this._matDialog.open<CustomSearchComponent<C>>(CustomSearchComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['no-padding'],
    });
  }

  protected toNumber (value: string | number | null | undefined): number | null {
    if (typeof value === 'number') return value;
    if (typeof value === 'string') return parseInt(value);
    return null;
  }


  @Input()
  get selectable(): boolean { return this._selectable; }
  set selectable(value: boolean | string) {
    this._selectable = coerceBooleanProperty(value);

    if (this._selectable) this._columns = ['select', ...this.columns];
    else                  this._columns = this.columns.filter(x => x !== 'select');
  }
  private _selectable: boolean = false;

  @Input()
  get delete(): boolean { return this._delete; }
  set delete(value: boolean | string) {
    this._delete = coerceBooleanProperty(value);

    if (this._delete) this.columns = [...this.columns, 'actions'];
    else              this.columns = this.columns.filter(x => x !== 'actions');
  }
  private _delete: boolean;

  @Input()
  get add(): boolean { return this._add; }
  set add(value: boolean | string) {
    this._add = coerceBooleanProperty(value);
  }
  private _add: boolean = true;

  @Input()
  get showLoading(): boolean { return this._showLoading; }
  set showLoading(value: boolean) {
    this._showLoading = coerceBooleanProperty(value);
  }
  private _showLoading: boolean = true;

  @Input()
  get paginator(): boolean { return this._paginator; }
  set paginator(value: boolean) {
    this._paginator = coerceBooleanProperty(value);
  }
  private _paginator: boolean = true;

  @Input()
  get toolbar(): boolean { return this._toolbar; }
  set toolbar(value: boolean) {
    this._toolbar = coerceBooleanProperty(value);
  }
  private _toolbar: boolean = true;

  @Input()
  get search(): boolean { return this._search; }
  set search(value: boolean) {
    this._search = coerceBooleanProperty(value);
  }
  private _search: boolean = true;

  @Input()
  get editable(): boolean { return this._editable; }
  set editable(value: boolean) {
    this._editable = coerceBooleanProperty(value);

    // deselect all when not editable
    if ( ! this._editable) this.selection.deselectAll();
  }
  private _editable: boolean = false;

  @Input()
  get bulk(): boolean { return this._bulk; }
  set bulk(value: boolean) {
    this._bulk = coerceBooleanProperty(value);
  }
  private _bulk: boolean = true;

  @Input()
  get pageSize(): number { return this._pageSize; }
  set pageSize(value: number) {
    this._pageSize = coerceNumberProperty(value);
  }
  private _pageSize: number = 10;

  @Input()
  get pageSizes(): number[] { return this._pageSizes; }
  set pageSizes(value: number[]) {
    this._pageSizes = coerceArray(value).map((val: number) => coerceNumberProperty(val));
  }
  private _pageSizes: number[] = [10, 30, 50, 100, 250, 500];

  // only for the events table
  @Input()
  get course(): string { return this._course; }
  set course(value: string) { this._course = value; }
  private _course: string;
}