
<mat-dialog-actions align="end">
  <h2 mat-dialog-title class="no-padding flex flex-justify-center">{{ 'common.create_course' | translate }}</h2>
  <span class="spacer" [style.min-width.em]="3"></span>
  <div class="dense-3">
    <mat-button-toggle-group [(ngModel)]="tabIndex">
      <mat-button-toggle [value]="0">{{ 'dialogs.create-courses.by-hand.label'     | translate | uppercase }}</mat-button-toggle>
      <mat-button-toggle [value]="1">{{ 'dialogs.create-courses.in-bulk.label'     | translate | uppercase }}</mat-button-toggle>
      <mat-button-toggle [value]="2">{{ 'dialogs.create-courses.using-excel.label' | translate | uppercase }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</mat-dialog-actions>

<mat-dialog-content>

  <mat-tab-group [(selectedIndex)]="tabIndex">
    <mat-tab>

      <div class="wrapper">
        <app-form *ngFor="let fg of manualFormArray.controls; let i = index"
          [formGroup]="fg"
          [bulk]="false"
          [class.dense-3]="manualFormArray.length > 3"
          (removed)="remove(manualFormArray, i)"
          (onPaste)="paste(manualFormArray, i, $event)"
        ></app-form>
      </div>

    </mat-tab>
    <mat-tab>

      @if (type == 'school') {
        <!-- classes, other groups and teachers select -->

        <div class="for-each-of-selected">

          <span>{{ 'dialogs.create-courses.in-bulk.for_each_of' | translate | capitalizeFirst }}</span>

          <div class="select-elements">
            @for (x of select; track $index) {
              <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic"
                [class.hidden]="select.items()?.length === 0"
              >
                <mat-icon matPrefix>{{ x.collection | collectionIcon }}</mat-icon>
                <mat-label>{{ x.label | translate }}</mat-label>
                <app-select #select multiselect [type]="x.type" [(ngModel)]="x.selected" [placeholder]="'common.select' | translate"/>
              </mat-form-field>
            }
          </div>

          <!-- if there are no teachers or groups -->
          @if ((totNumOptions | async) === 0) {
            <span>{{ 'dialogs.create-courses.in-bulk.no_options' | translate }}</span>
          }

        </div>

      } @else {
        <!-- subject select -->

        <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="for-each-of-selected">
          <mat-label>{{ 'dialogs.create-courses.in-bulk.for_each_of' | translate | capitalizeFirst }}</mat-label>

          <mat-chip-grid #chipList>
            <mat-chip-row *ngFor="let x of onSelectedSubjects | async" (removed)="removeSubject(x)">
              {{ x }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input
              #entityInput
              [formControl]="entityCtrl"
              [placeholder]="(((onSelectedSubjects | async)?.length ? 'common.add_one_more' : 'common.the_subjects') | translate | capitalizeFirst) + '...'"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              matChipInputAddOnBlur
              (matChipInputTokenEnd)="addSubject($event)"
            >
          </mat-chip-grid>

        </mat-form-field>
      }

      <div class="wrapper">
        <app-form *ngFor="let fg of bulkFormArray.controls; let i = index"
          [formGroup]="fg"
          [bulk]="true"
          [class.dense-3]="bulkFormArray.length > 3"
          (removed)="remove(bulkFormArray, i)"
          (onPaste)="paste(bulkFormArray, i, $event)"
        ></app-form>
      </div>

    </mat-tab>
    <mat-tab>

      <app-excel #excel></app-excel>

    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
  @if (tabIndex() == 2) {
    <button mat-button color="accent" @inAnimation
      cypress="reset"
      (click)="excel.reset()"
    >
      {{ 'common.reset' | translate }}
    </button>
  } @else {
    <button mat-button color="accent" @inAnimation
      cypress="one-more"
      (click)="addCourse($event)"
    >
      {{ 'common.add_one_more' | translate }}
    </button>
  }
  <span class="spacer" [style.min-width.em]="3"></span>
  <button mat-button mat-dialog-close type="button">
    {{ 'common.cancel' | translate }}
  </button>
  <button mat-button color="primary" type="button"
    [disabled]=" ! (numCourses | async)"
    cypress="submit"
    (click)="submit()"
  >
    {{ 'common.create' | translate }}
    {{ numCourses | async | allowGreaterThan:1 }}
  </button>
</mat-dialog-actions>
