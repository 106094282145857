@if(toolbar) {
  <mat-toolbar class="app-table-toolbar">
    @if (search) {
      @let disabled = onNoData | async;
      <app-search [disabled]="disabled" [placeholder]="'common.search.' + collection"></app-search>

      <button mat-icon-button
        [disabled]="disabled"
        (click)="openCustomSearchDialog()"
        [tooltip]="'common.custom_search' | translate"
        tooltipTutorial="29"
      >
        <mat-icon [matBadge]="(isDefaultSearchParameters | async) ? null : '!'" aria-hidden="false">tune</mat-icon>
      </button>
    }

    <!-- <mat-form-field class="filter" appearance="outline" subscriptSizing="dynamic">
      <mat-select (selectionChange)="typeSelect.next($event.value)"
                  [placeholder]="'common.filter' | translate">
        <mat-option *ngFor="let type of typeOptions" [value]="type.value">
          {{ type.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <div class="spacer"></div>

    <button mat-button
            class="edit-columns royal-text-secondary"
            [class.hidden]="onNoData | async"
            (click)="openEditColumnsDialog()"
            [tooltip]="'common.edit_columns' | translate"
            tooltipTutorial="16"
            cypress="open-edit-columns-dialog">
      <mat-icon class="material-symbols-outlined">view_week</mat-icon>
      {{ 'common.edit_columns' | translate }}
    </button>

    @if(add) {
      <button mat-flat-button
              [disabled]=" ! editable || ! (appFeatures.CREATE_GROUPS | hasAppFeature)"
              color="accent-tonal"
              class="add"
              [tooltip]="'common.create_group' | translate"
              tooltipTutorial="30"
              (click)="openCreateEntityDialog()">
        {{ 'common.create_group' | translate }}
        <mat-icon>add</mat-icon>
      </button>
    }
  </mat-toolbar>
}

<div class="app-table-container" #tableContainer>
  <app-empty collection="groups" [visible]="onNoData | async"></app-empty>
  <app-no-columns [visible]="noDataColumns | async"></app-no-columns>
  <app-loading [loading]="dataSource.loading$ | async"></app-loading>

  <table #table mat-table
    [dataSource]="dataSource"
    [trackBy]="trackBy"
    recycleRows
    matSort
    class="default-hover-effect"
  >

    <ng-container matColumnDef="select" sticky>
      <th mat-header-cell *matHeaderCellDef class="transparent-right-border">
        <mat-checkbox (change)="$event ? selection.toggleAll() : null"
                      [checked]="selection.checked"
                      [indeterminate]="selection.indeterminate"
                      [disabled]=" ! editable">
        </mat-checkbox>
        <div class="divider one-px"></div>
      </th>
      <td mat-cell *matCellDef="let row; table: table">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [disabled]=" ! editable">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="species">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.species" [description]="columnDescriptionTranslationKeys.species" tutorial="37"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div>
          {{ element | groupType }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="parentGroups">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.parentGroups" [description]="columnDescriptionTranslationKeys.parentGroups"></app-column-header>
      </th>
      <td mat-cell
          *matCellDef="let element; table: table"
          [class.disabled-cell]="element.species"
          style="cursor: not-allowed;">
        @if (! element.species) {
          {{ element.parentGroups | displayNames }}
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="ids">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.ids" [description]="columnDescriptionTranslationKeys.ids"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-text [value]="element.ids"
                              saveOnFocusLost
                              nullifyEmpty
                              *ngIf="editable"
                              (onChange)="edit(element.id, { ids: $event })">
        </app-form-field-text>
        <div *ngIf="! editable" class="overflow-text">{{ element.ids }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.createdAt" [description]="columnDescriptionTranslationKeys.createdAt"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div class="overflow-text">{{ element.createdAt }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.tags"
                            [description]="columnDescriptionTranslationKeys.tags"
                            tutorial="39"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-tags
          [value]="element.tags"
          [disabled]=" ! editable"
          [tagOptions]="tags$ | async"
          nullable
          saveOnClose
          (onChange)="edit(element.id, { tags: $event })"
        >
        </app-form-field-tags>
    </ng-container>

    <ng-container matColumnDef="members">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.members" [description]="columnDescriptionTranslationKeys.members"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div formField="persons"
          collection="groups"
          path="members"
          flat
          [id]="element.id"
          [value]="element.members"
          [editable]="editable"
        >
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.displayName" [description]="columnDescriptionTranslationKeys.displayName"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-text [value]="element.displayName"
                              saveOnFocusLost
                              *ngIf="editable"
                              (onChange)="edit(element.id, { displayName: $event })">
        </app-form-field-text>
        <div *ngIf="! editable" class="overflow-text">{{ element.displayName }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="lunch">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.lunch"
                            [description]="columnDescriptionTranslationKeys.lunch"
                            tutorial="4"></app-column-header>
      </th>
      <td mat-cell
          *matCellDef="let element; table: table"
          [class.disabled-cell]="! element.species">
        @if(element.species) {
          <app-form-field-dynamic-locked-times [value]="element.lunch ?? []"
                                                [numDays]="settings | async | pluck:'numDays'"
                                                *ngIf="editable"
                                                voidText="-"
                                                hideOptionDefault
                                                (onChange)="edit(element.id, { lunch: $event })"
                                                disableActions
                                                saveOnClose>
          </app-form-field-dynamic-locked-times>
          <app-form-field-dynamic-locked-times-display-value [value]="element.lunch"
                                                              [numDays]="settings | async | pluck:'numDays'"
                                                              voidText="-"
                                                              *ngIf="! editable">
          </app-form-field-dynamic-locked-times-display-value>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="minBreakLength">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.minBreakLength"
                            [description]="columnDescriptionTranslationKeys.minBreakLength"
                            tutorial="21"></app-column-header>
      </th>
      <td mat-cell
          *matCellDef="let element; table: table"
          [class.disabled-cell]="! element.species">
        @if(element.species) {
          <app-form-field-break-length [value]="element.minBreakLength"
                                        hideOptionAsymmetrical
                                        hideOptionNone
                                        *ngIf="editable"
                                        [defaultValue]="0"
                                        [voidText]="(settings | async)?.defaultGroupMinimumBreakLength + ' ' + ('common.minutes' | translate | lowercase)"
                                        [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.defaultGroupMinimumBreakLength }"
                                        disableActions
                                        saveOnClose
                                        [defaultValue]="(settings | async)?.defaultGroupMinimumBreakLength"
                                        (onChange)="edit(element.id, { minBreakLength: $any($event) })">
          </app-form-field-break-length>
          <app-form-field-break-length-display-value [value]="element.minBreakLength"
                                                      [voidText]="(settings | async)?.defaultGroupMinimumBreakLength + ' ' + ('common.minutes' | translate | lowercase)"
                                                      *ngIf="! editable">
          </app-form-field-break-length-display-value>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="lockedTimes">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.lockedTimes"
                            [description]="columnDescriptionTranslationKeys.lockedTimes"
                            tutorial="9"></app-column-header>
      </th>
      <td mat-cell
          *matCellDef="let element; table: table"
          [class.disabled-cell]="! element.species">
        @if(element.species) {
          <app-form-field-locked-times [value]="element.lockedTimes"
                                        [numDays]="settings | async | pluck:'numDays'"
                                        voidText="-"
                                        disableActions
                                        *ngIf="editable"
                                        saveOnClose
                                        (onChange)="edit(element.id, { lockedTimes: $event })">
          </app-form-field-locked-times>
          <app-form-field-locked-times-display-value [value]="element.lockedTimes"
                                                      *ngIf="! editable"
                                                      [voidText]="'-'">
          </app-form-field-locked-times-display-value>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="rootIntervals">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.rootIntervals" [description]="columnDescriptionTranslationKeys.rootIntervals"></app-column-header>
      </th>
      <td mat-cell
          *matCellDef="let element; table: table"
          [class.disabled-cell]="! element.species">
        @if(element.species) {
          <app-form-field-root-intervals [value]="element.rootInterval"
                                          voidText="-"
                                          disableActions
                                          *ngIf="editable"
                                          [options]="rootIntervals | async"
                                          saveOnClose
                                          [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd }"
                                          (onChange)="edit(element.id, { rootInterval: $event })">
          </app-form-field-root-intervals>
          <app-form-field-root-intervals-display-value [value]="element.rootInterval"
                                                        *ngIf="! editable"
                                                        [voidText]="(settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd">
          </app-form-field-root-intervals-display-value>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="intervals">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.intervals"
                            [description]="columnDescriptionTranslationKeys.intervals"
                            tutorial="3"></app-column-header>
      </th>
      <td mat-cell
          *matCellDef="let element; table: table"
          [class.disabled-cell]="! element.species">
        @if(element.species) {
          <app-form-field-intervals [value]="element.intervals"
                                    [numDays]="settings | async | pluck:'numDays'"
                                    [voidText]="(settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd"
                                    disableActions
                                    [availableDays]="element.days"
                                    *ngIf="editable"
                                    saveOnClose
                                    [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd }"
                                    (onChange)="edit(element.id, { intervals: $event })">
          </app-form-field-intervals>
          <app-form-field-intervals-display-value [value]="element.intervals"
                                                  [numDays]="settings | async | pluck:'numDays'"
                                                  *ngIf="! editable"
                                                  [voidText]="(settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd">
          </app-form-field-intervals-display-value>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="days">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.days"
                            [description]="columnDescriptionTranslationKeys.days"
                            tutorial="8"></app-column-header>
      </th>
      <td mat-cell
          *matCellDef="let element; table: table"
          [class.disabled-cell]="! element.species">
        @if(element.species) {
          <app-form-field-available-days [value]="element.days"
                                          [numDays]="settings | async | pluck:'numDays'"
                                          (onChange)="edit(element.id, { days: $event })"
                                          *ngIf="editable"
                                          saveOnClose
                                          disableActions>
          </app-form-field-available-days>
          <app-form-field-available-days-display-value [value]="element.days"
                                                        *ngIf="! editable">
          </app-form-field-available-days-display-value>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; table: table">
        <div class="flex flex-justify-end">
          @if (appFeatures.CREATE_GROUPS | hasAppFeature) {
            <button mat-icon-button
                    [disabled]=" ! editable"
                    type="button"
                    (click)="copy(element, 'parentGroups', 'displayName', 'species', element.species ? '' :'members')">
              <mat-icon class="royal-icon"
                        matTooltip="{{ 'common.copy' | translate }}">
                content_copy
              </mat-icon>
            </button>
          }

          <button mat-icon-button
                  [disabled]=" ! editable"
                  type="button"
                  (click)="deleteOne(element.id)">
            <mat-icon class="royal-icon"
                      matTooltip="{{ 'common.delete' | translate }}">delete_outline</mat-icon>
          </button>
        </div>

      </td>
    </ng-container>


    <!----------------------------------------------------------------------->
    <!------------------------------ BULK EDIT ------------------------------>
    <!----------------------------------------------------------------------->
    <ng-container matColumnDef="bulk-select">
      <th mat-header-cell *matHeaderCellDef  class="sticky">
        <div class="collapsible">
          <div class="corner top"></div>
          <div class="corner bottom"></div>
          <div class="selected-count">
            {{ selection.length }}
          </div>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-members">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible"
              [class.disabled-cell]="! isSubSpeciesBulk">
          @if (isSubSpeciesBulk) {
            <div formField="persons"
              collection="groups"
              path="members"
              flat
              [value]="membersBulkValue"
              [editable]="editable"
              [id]="selection.selected"
            >
            </div>
          } @else {
            <div>{{ 'common.not_available_for_current_selection' | translate }}</div>
          }
            <!-- <app-form-field-groups [value]="membersBulkValue"
                                    [list]="persons | async"
                                    *ngIf="editable"
                                    type="persons"
                                    flat
                                    disableActions
                                    saveOnClose
                                    (onChange)="editMany(selection.selected, { members: $any($event) })">
            </app-form-field-groups> -->
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-species">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell">
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-displayName">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell">
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-parentGroups">
      <th mat-header-cell *matHeaderCellDef style="cursor: not-allowed;">
        <div class="collapsible"
              [class.disabled-cell]="! isSubSpeciesBulk">
          @if (isSubSpeciesBulk) {
            {{ parentGroupsBulkValue | displayNames }}
          } @else {
            <div>{{ 'common.not_available_for_current_selection' | translate }}</div>
          }
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-days">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible"
              [class.disabled-cell]="! isCompleteSpeciesBulk">
          @if (isCompleteSpeciesBulk) {
            <app-form-field-available-days [value]="daysBulkValue"
                                          [numDays]="settings | async | pluck:'numDays'"
                                          voidText="-"
                                          (onChange)="editMany(selection.selected, { days: $event })"
                                          saveOnClose
                                          disableActions>
            </app-form-field-available-days>
          } @else {
            <div>{{ 'common.not_available_for_current_selection' | translate }}</div>
          }
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-lockedTimes">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible"
              [class.disabled-cell]="! isCompleteSpeciesBulk">
          @if (isCompleteSpeciesBulk) {
            <app-form-field-locked-times [value]="lockedTimesBulkValue"
                                        [numDays]="settings | async | pluck:'numDays'"
                                        voidText="-"
                                        omitId
                                        disableActions
                                        saveOnClose
                                        (onChange)="editMany(selection.selected, { lockedTimes: $event })">
            </app-form-field-locked-times>
          } @else {
            <div>{{ 'common.not_available_for_current_selection' | translate }}</div>
          }
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-minBreakLength">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible"
              [class.disabled-cell]="! isCompleteSpeciesBulk">
          @if (isCompleteSpeciesBulk) {
            <app-form-field-break-length [value]="minBreakLengthBulkValue"
                                        coalesced
                                        disableActions
                                        saveOnClose
                                        hideOptionAsymmetrical
                                        hideOptionNone
                                        voidText="-"
                                        [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.defaultGroupMinimumBreakLength }"
                                        [defaultValue]="(settings | async)?.defaultGroupMinimumBreakLength"
                                        (onChange)="editMany(selection.selected, { minBreakLength: $any($event) })">
            </app-form-field-break-length>
          } @else {
            <div>{{ 'common.not_available_for_current_selection' | translate }}</div>
          }
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-lunch">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible"
              [class.disabled-cell]="! isCompleteSpeciesBulk">
          @if (isCompleteSpeciesBulk) {
            <app-form-field-dynamic-locked-times [value]="lunchBulkValue"
                                                  [numDays]="settings | async | pluck:'numDays'"
                                                  voidText="-"
                                                  disableActions
                                                  hideOptionDefault
                                                  *ngIf="editable"
                                                  saveOnClose
                                                  (onChange)="editMany(selection.selected, { lunch: $event })">
            </app-form-field-dynamic-locked-times>
            <app-form-field-dynamic-locked-times-display-value [value]="lunchBulkValue"
                                                                [numDays]="settings | async | pluck:'numDays'"
                                                                voidText="-"
                                                                *ngIf="! editable">
            </app-form-field-dynamic-locked-times-display-value>
          } @else {
            <div>{{ 'common.not_available_for_current_selection' | translate }}</div>
          }

        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-rootIntervals">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible"
              [class.disabled-cell]="! isCompleteSpeciesBulk">
          @if (isCompleteSpeciesBulk) {
            <app-form-field-root-intervals [value]="rootIntervalBulkValue"
                                            voidText="-"
                                            disableActions
                                            *ngIf="editable"
                                            [options]="rootIntervals | async"
                                            saveOnClose
                                            [setVoidText]="'common.useDefaultValue' | translate"
                                            (onChange)="editMany(selection.selected, { rootInterval: $event })">
            </app-form-field-root-intervals>
          } @else {
            <div>{{ 'common.not_available_for_current_selection' | translate }}</div>
          }
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-intervals">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible"
              [class.disabled-cell]="! isCompleteSpeciesBulk">
          @if (isCompleteSpeciesBulk) {
            <app-form-field-intervals [value]="intervalsBulkValue"
                                      [numDays]="settings | async | pluck:'numDays'"
                                      voidText="-"
                                      disableActions
                                      [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd }"
                                      saveOnClose
                                      (onChange)="editMany(selection.selected, { intervals: $event })">
            </app-form-field-intervals>
          } @else {
            <div>{{ 'common.not_available_for_current_selection' | translate }}</div>
          }
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-ids">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell"></th>
    </ng-container>

    <ng-container matColumnDef="bulk-createdAt">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell"></th>
    </ng-container>

    <ng-container matColumnDef="bulk-tags">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-tags
            [value]="tagsBulkValue"
            [disabled]=" ! editable"
            [tagOptions]="tags$ | async"
            nullable
            saveOnClose
            (onChange)="bulkUpdateTags(selection.selection, $event)"
          >
          </app-form-field-tags>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-actions">
      <th mat-header-cell *matHeaderCellDef class="sticky">
        <div class="collapsible flex-align-center">
          <div class="border"></div>
          <div class="corner top"></div>
          <div class="corner bottom"></div>
          <span class="spacer"></span>
          <button mat-icon-button
                  [disabled]=" ! editable"
                  type="button"
                  class="bulk-delete"
                  (click)="deleteMany(selection.selected)">
            <mat-icon class="royal-icon"
                      matTooltip="{{ 'common.delete' | translate }}"
                      [matBadge]="selection.length"
                      matBadgeOverlap="true"
                      matBadgeColor="warn"
                      aria-hidden="false">delete_outline</mat-icon>
          </button>
        </div>
      </th>
    </ng-container>

    <!-- a column that absorbs width if for example no column is visible -->
    <!-- the colspan=2 also fixes the stickyEnd bug where columns are positioned above the table header -->
    <ng-container matColumnDef="absorber">
      <!-- <th mat-header-cell *matHeaderCellDef colspan="2"></th> -->
      <th mat-header-cell *matHeaderCellDef colspan="1"></th>
      <td mat-cell *matCellDef="let element; table: table"></td>
    </ng-container>
    <ng-container matColumnDef="bulk-absorber">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; table: table"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>

    <!-- bulk edit table header -->
    <tr mat-header-row *matHeaderRowDef="bulkColumns;"
        class="bulk" [class.hidden]="selection.empty" [class.animate]="dataSource.loading$ | async | not"></tr>


    <tr mat-row
        class="table-row"
        *matRowDef="let row; columns: columns;">
    </tr>

  </table>
</div>

@if(paginator) {
  <mat-paginator showFirstLastButtons
                  [length]="dataSource ? (dataSource.totNumDocs$ | async) : 0"
                  [pageIndex]="0"
                  [pageSize]="pageSize"
                  [pageSizeOptions]="pageSizes">
  </mat-paginator>
}