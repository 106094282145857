import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';
import { NgPipesModule                           } from 'ngx-pipes';

import { AppCommonModule                         } from 'app/common/common.module';
import { TranslationModule                       } from 'app/core/translate/translate.module';
import { SharedPipesModule                             } from 'app/shared/pipes/pipes.module';
import { SharedDirectivesModule                        } from 'app/shared/directives/directives.module';
import { SharedFormsModule      as AppFormsModule      } from 'app/shared/forms/forms.module';
import { SharedComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';

import { ComponentsModule                        } from '../components/components.module';
import { EventsComponent                         } from './events.component';
import { CombineCourseNamesPipe,
         CombineCourseSubjectsPipe               } from './events.pipe';
import { CustomSearchComponent } from '../components/custom-search/custom-search.component';
import { HasAppFeaturePipe } from "../../../../core/environment/environment.pipe";
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { DurationComponent } from '@app/shared/form-fields/duration/duration.component';
import { DisplayValueComponent as DurationDisplayValueComponent } from '@app/shared/form-fields/duration/components/display-value/display-value.component';
import { FormFieldsDirective } from '@app/shared/form-fields/form-fields.directive';
import { BreakLengthComponent } from '@app/shared/form-fields/break-length/break-length.component';
import { DisplayValueComponent as BreakLengthDisplayValueComponent } from '@app/shared/form-fields/break-length/components/display-value/display-value.component';
import { AvailableDaysComponent } from '@app/shared/form-fields/available-days/available-days.component';
import { DisplayValueComponent as AvailableDaysDisplayValueComponent } from '@app/shared/form-fields/available-days/components/display-value/display-value.component';
import { PeriodComponent } from '@app/shared/form-fields/period/period.component';
import { DisplayValueComponent as PeriodDisplayValueComponent } from '@app/shared/form-fields/period/components/display-value/display-value.component';
import { AvailableLocationsComponent } from '@app/shared/form-fields/available-locations/available-locations.component';
import { CenterOfAttractionComponent } from '@app/shared/form-fields/center-of-attraction/center-of-attraction.component';
import { DisplayValueComponent as CenterOfAttractionDisplayValueComponent } from '@app/shared/form-fields/center-of-attraction/components/display-value/display-value.component';
import { ColorComponent } from '@app/shared/form-fields/color/color.component';
import { DisplayValueComponent as ColorDisplayValueComponent } from '@app/shared/form-fields/color/components/display-value/display-value.component';
import { FixedStartComponent } from '@app/shared/form-fields/fixed-start/fixed-start.component';
import { DisplayValueComponent as FixedStartDisplayValueComponent } from '@app/shared/form-fields/fixed-start/components/display-value/display-value.component';
import { IntervalsComponent } from '@app/shared/form-fields/intervals/intervals.component';
import { DisplayValueComponent as IntervalsDisplayValueComponent } from '@app/shared/form-fields/intervals/components/display-value/display-value.component';
import { LockedTimesComponent } from '@app/shared/form-fields/locked-times/locked-times.component';
import { DisplayValueComponent as LockedTimesDisplayValueComponent } from '@app/shared/form-fields/locked-times/components/display-value/display-value.component';
import { TagsComponent } from '@app/shared/form-fields/tags/tags.component';
import { DisplayValueComponent as TagsDisplayValueComponent } from '@app/shared/form-fields/tags/components/display-value/display-value.component';
import { TextComponent } from '@app/shared/form-fields/text/text.component';
import { SearchComponent } from '@app/shared/components/search/search.component';
import { TypeSafeMatCellDefDirective } from '@app/shared/directives/type-safe-mat-cell-def/type-safe-mat-cell-def.directive';

@NgModule({
  declarations: [
    EventsComponent,
    CombineCourseNamesPipe,
    CombineCourseSubjectsPipe
  ],
  exports: [
    EventsComponent
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    AppCommonModule,
    TranslationModule,
    SharedDirectivesModule,
    SharedPipesModule,
    AppFormsModule,
    AppComponentsModule,
    ComponentsModule,
    SearchComponent,
    CustomSearchComponent,
    HasAppFeaturePipe,
    LoadingComponent,
    FormFieldsDirective,
    TextComponent,
    DurationComponent, DurationDisplayValueComponent,
    BreakLengthComponent, BreakLengthDisplayValueComponent,
    AvailableDaysComponent, AvailableDaysDisplayValueComponent,
    PeriodComponent, PeriodDisplayValueComponent,
    AvailableLocationsComponent,
    CenterOfAttractionComponent, CenterOfAttractionDisplayValueComponent,
    ColorComponent, ColorDisplayValueComponent,
    FixedStartComponent, FixedStartDisplayValueComponent,
    IntervalsComponent, IntervalsDisplayValueComponent,
    LockedTimesComponent, LockedTimesDisplayValueComponent,
    TagsComponent, TagsDisplayValueComponent,
    TypeSafeMatCellDefDirective
  ]
})
export class EventsModule { }
