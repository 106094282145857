import { Pipe,
         PipeTransform                   } from '@angular/core';

import { Group                           } from 'app/shared/interfaces';
import { TranslateService                } from 'app/core';


@Pipe({
    name: 'groupType',
    standalone: false
})
export class GroupTypePipe implements PipeTransform {

  constructor(private _translate: TranslateService) {}

  transform(group: Group.populated): string {
    if (group.species === 'class')
      return this._translate.instant('attributes.groups.species.class');

    if (group.species == null)
      return this._translate.instant('attributes.groups.species.none');

    return this._translate.instant('common.nameless');
  }
}
