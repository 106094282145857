@if(toolbar) {
  <mat-toolbar class="app-table-toolbar">
    @if (search) {
      @let disabled = onNoData | async;
      <app-search [disabled]="disabled" [placeholder]="'common.search.' + collection"></app-search>

      <button mat-icon-button
        [disabled]="disabled"
        (click)="openCustomSearchDialog()"
        [tooltip]="'common.custom_search' | translate"
        tooltipTutorial="29"
      >
        <mat-icon [matBadge]="(isDefaultSearchParameters | async) ? null : '!'" aria-hidden="false">tune</mat-icon>
      </button>
    }

    <div class="spacer"></div>

    <button mat-button
            class="edit-columns royal-text-secondary"
            [class.hidden]="onNoData | async"
            (click)="openEditColumnsDialog()"
            [tooltip]="'common.edit_columns' | translate"
            tooltipTutorial="16"
            cypress="open-edit-columns-dialog">
      <mat-icon class="material-symbols-outlined">view_week</mat-icon>
      {{ 'common.edit_columns' | translate }}
    </button>

    @if(add) {
      <button mat-flat-button
              [disabled]=" ! editable || ! (appFeatures.CREATE_COURSES | hasAppFeature)"
              color="accent-tonal"
              class="add"
              [tooltip]="'common.create_course' | translate"
              tooltipTutorial="28"
              (click)="openCreateCoursesDialog()">
        {{ 'common.create_course' | translate }}
        <mat-icon>add</mat-icon>
      </button>
    }

  </mat-toolbar>
}

<div class="app-table-container" #tableContainer>
  <app-empty collection="courses" [visible]="onNoData | async"></app-empty>
  <app-no-columns [visible]="noDataColumns | async"></app-no-columns>
  <app-loading [loading]="dataSource.loading$ | async"></app-loading>

  <table #table mat-table
    [dataSource]="dataSource"
    [trackBy]="trackBy"
    multiTemplateDataRows
    matSort>

    <ng-container matColumnDef="select" sticky>
      <th mat-header-cell *matHeaderCellDef class="colored-border transparent-right-border">
        <mat-checkbox (change)="$event ? selection.toggleAll() : null"
                      [checked]="selection.checked"
                      [indeterminate]="selection.indeterminate"
                      [disabled]=" ! editable">
        </mat-checkbox>
        <div class="divider three-px"></div>
      </th>
      <td mat-cell *matCellDef="let element; table: table" class="colored-border">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(element) : null"
                      [checked]="selection.isSelected(element)"
                      [disabled]=" ! editable">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="ids">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.ids" [description]="columnDescriptionTranslationKeys.ids"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-text [value]="element.ids"
                              nullifyEmpty
                              saveOnFocusLost
                              *ngIf="editable"
                              (onChange)="edit(element.id, { ids: $event })">
        </app-form-field-text>
        <div *ngIf="! editable" class="overflow-text">{{ element.ids }}</div>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.createdAt" [description]="columnDescriptionTranslationKeys.createdAt"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div class="overflow-text no-wrap">{{ element.createdAt }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.tags"
                            [description]="columnDescriptionTranslationKeys.tags"
                            tutorial="39"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-tags
          [value]="element.tags"
          [disabled]=" ! editable"
          [tagOptions]="tags$ | async"
          nullable
          saveOnClose
          (onChange)="edit(element.id, { tags: $event })">
        </app-form-field-tags>
      </td>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.displayName" [description]="columnDescriptionTranslationKeys.displayName"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-text [value]="element.displayName"
                              saveOnFocusLost
                              *ngIf="editable"
                              (onChange)="edit(element.id, { displayName: $event })">
        </app-form-field-text>
        <div *ngIf="! editable" class="overflow-text">{{ element.displayName }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.subject" [description]="columnDescriptionTranslationKeys.subject"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-form-field-text [value]="element.subject"
                              saveOnFocusLost
                              *ngIf="editable"
                              (onChange)="edit(element.id, { subject: $event })">
        </app-form-field-text>
        <div *ngIf="! editable" class="overflow-text">{{ element.subject }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="events">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.events"
                            [description]="columnDescriptionTranslationKeys.events"
                            tutorial="26">
        </app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table">
        <div class="flex-align-center">
          <div class="events-container">
            <div *ngFor="let event of element.events; let last = last"
                  [style.width.px]="(event?.preferredDuration ?? 10) * 0.7"
                  [style.background-color]="event | eventColor:'20'"
                  matTooltipClass="new-line-tooltip"
                  [matTooltip]="element | eventTooltip:event:element.events"
                  class="box event-div">
              {{ event?.preferredDuration ?? '??' }}
            </div>
            @if ((element.events?.length ?? 0) > 1) {
              <div class="summed-duration">
                ({{ element.events | pluck:'preferredDuration' | sum }})
              </div>
            }
          </div>
          <span class="spacer"></span>
          @if (element.events?.length) {
            @if (element.id == expandedElement()?.id) {
              <button mat-icon-button type="button"
                (click)="expandedElement.set(null)"
                [matTooltip]="'common.hide' | translate"
              >
                <mat-icon>unfold_less</mat-icon>
              </button>
            } @else {
              <button mat-icon-button type="button"
                (click)="expandedElement.set(element)"
                [matTooltip]="'common.show' | translate"
              >
                <mat-icon>unfold_more</mat-icon>
              </button>
            }
          }
          @if (appFeatures.CREATE_EVENTS | hasAppFeature) {
            @let durationSet = preferences.durationSets.at(0) ?? defaultDurationSet;

              <button mat-icon-button type="button"
                [disabled]=" ! editable"
                (click)="createEvents([element], durationSet)"
                [matTooltip]="'common.add_event' | translate:{ 'event-durations': durationSet.join(', ') + (durationSet.length > 1 ? ' (' + (durationSet | sum) + ')' : '') }"
              >
                <mat-icon class="royal-icon">post_add</mat-icon>
              </button>
              <button mat-icon-button type="button"
                  [disabled]=" ! editable"
                  [matMenuTriggerFor]="eventDurationMenu"
                  [matTooltip]="'common.add_event_advanced' | translate"
              >
                <mat-icon class="royal-icon">more_vert</mat-icon>
              </button>
              <mat-menu #eventDurationMenu="matMenu"
                        class="select-event-duration">
                <ng-template matMenuContent>
                  @for (durationSet of preferences.durationSets | restrictSets:element:(numWeeksPerPeriod$ | async | get:(element.period?.id ?? (settings | async)?.period?.id ?? undefined)); track $index) {
                    <button mat-menu-item
                            class="events-container"
                            (click)="createEvents([element], durationSet.val)"
                            [class.warning]="durationSet.warning">
                      <span *ngFor="let duration of durationSet.val"
                            class="box event-div"
                            [style.width.px]="(duration ?? 10) * 0.7"
                      >
                        {{ duration }}
                      </span>
                      @if (durationSet.warning || durationSet.val.length > 1) {
                        <span class="summed-duration">
                          ({{ durationSet.sum }})
                        </span>
                      }
                    </button>
                  }
                  <button mat-menu-item (click)="otherEventDuration([element])">
                    {{ 'common.other' | translate }}
                  </button>
                </ng-template>
              </mat-menu>
          }

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="plannedDuration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <app-column-header [title]="columnTitleTranslationKeys.plannedDuration"
                            [description]="columnDescriptionTranslationKeys.plannedDuration"
                            tutorial="11">
        </app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
        [class.value-warning]=" ! (element | plannedDurationIsSatisfied:(numWeeksPerPeriod$ | async | get:(element.period?.id ?? (settings | async)?.period?.id ?? undefined)))"
      >

        <div class="notice">
          <div class="middle" [matTooltip]="'tables.courses.values.plannedDuration.warning' | translate"></div>
        </div>

        <app-form-field-planned-duration
          [value]="element.plannedDuration ?? null"
          [numberOfWeeksInPeriod]="numWeeksPerPeriod$ | async | get:(element.period?.id ?? (settings | async)?.period?.id ?? undefined)"
          nullable
          nullifyEmpty
          [disabled]=" ! editable"
          saveOnClose
          (onChange)="edit(element.id, { plannedDuration: $event })"
        >
        </app-form-field-planned-duration>

      </td>
    </ng-container>

    <ng-container matColumnDef="eventDurationVariance">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.eventDurationVariance"
                            [description]="columnDescriptionTranslationKeys.eventDurationVariance"
                            tutorial="25">
        </app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'eventDurationVariance':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>

        <app-form-field-text [value]="element.eventDurationVariance"
                    saveOnFocusLost
                    nullifyEmpty
                    validate="multiple-of-five"
                    maxWidth="2em"
                    class="push-suffix-left"
                    [voidText]="(settings | async)?.defaultEventDurationVariance"
                    prefix="±"
                    [suffix]="'common.minutes' | translate | lowercase"
                    *ngIf="editable"
                    (onChange)="edit(element.id, { eventDurationVariance: toNumber($event) })">
        </app-form-field-text>
        @if ( ! editable) {
          <div class="overflow-text">
            ±
            {{ element.eventDurationVariance ?? (settings | async)?.defaultEventDurationVariance }}
            {{ 'common.minutes' | translate | lowercase }}
          </div>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="locations">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.locations"
                            [description]="columnDescriptionTranslationKeys.locations"
                            tutorial="20">
        </app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'locations':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <div
          formField="available-locations"
          collection="courses"
          path="locations"
          [id]="element.id"
          [value]="element.locations"
          [editable]="editable"
          [config]="availableLocationsConfig"
        ></div>
        <!-- <app-form-field-available-locations [value]="element.locations"
                                            [list]="locations | async"
                                            voidText="-"
                                            add
                                            reset
                                            disableActions
                                            *ngIf="editable"
                                            saveOnClose
                                            (onChange)="edit(element.id, { locations: $event })">
        </app-form-field-available-locations> -->
        <!-- <app-form-field-available-locations-display-value [value]="element.locations"
                                                          voidText="-"
                                                          *ngIf="! editable">
        </app-form-field-available-locations-display-value> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="days">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.days"
                            [description]="columnDescriptionTranslationKeys.days"
                            tutorial="8">
        </app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'days':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <app-form-field-available-days [value]="element.days"
                                        [numDays]="settings | async | pluck:'numDays'"
                                        (onChange)="edit(element.id, { days: $event })"
                                        voidText="{{ 'common.all' | translate }}"
                                        saveOnClose
                                        *ngIf="editable"
                                        disableActions>
        </app-form-field-available-days>
        <app-form-field-available-days-display-value [value]="element.days"
                                                      *ngIf="! editable"
                                                      voidText="{{ 'common.all' | translate }}">
        </app-form-field-available-days-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="teachers">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.teachers"
                            [description]="columnDescriptionTranslationKeys.teachers"
                            tutorial="10">
        </app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'teachers':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <div formField="teachers"
            [id]="element.id"
            path="teachers"
            collection="courses"
            [value]="element.teachers"
            [editable]="editable">
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="minBreakLength">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.minBreakLength"
                            [description]="columnDescriptionTranslationKeys.minBreakLength"
                            tutorial="21"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'minBreakLength':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <app-form-field-break-length [value]="element.minBreakLength"
                                      disableActions
                                      saveOnClose
                                      [voidText]="(settings | async)?.defaultEventMinimumBreakLength + ' ' + ('common.minutes' | translate | lowercase)"
                                      [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.defaultEventMinimumBreakLength + ' ' + ('common.minutes' | translate | lowercase) }"
                                      *ngIf="editable"
                                      (onChange)="edit(element.id, { minBreakLength: $event })">
        </app-form-field-break-length>
        <app-form-field-break-length-display-value [value]="element.minBreakLength"
                                                    [voidText]="(settings | async)?.defaultEventMinimumBreakLength + ' ' + ('common.minutes' | translate | lowercase)"
                                                    *ngIf="! editable">
        </app-form-field-break-length-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="participants">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.participants"
                            [description]="columnDescriptionTranslationKeys.participants"
                            tutorial="10">
        </app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'participants':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <div formField="persons"
          [id]="element.id"
          path="participants"
          collection="courses"
          [value]="element.participants"
          [editable]="editable"
        >
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="groups">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.groups"
                            [description]="columnDescriptionTranslationKeys.groups"
                            tutorial="10"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'groups':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <div formField="groups-persons"
          [id]="element.id"
          collection="courses"
          path="groups"
          [value]="element.groups"
          [editable]="editable"
          coalescedType="persons"
          [coalescedValue]="element.participants"
          coalescedPath="participants"
        >
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="lockedTimes">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.lockedTimes"
                            [description]="columnDescriptionTranslationKeys.lockedTimes"
                            tutorial="9">
        </app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'lockedTimes':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <app-form-field-locked-times [value]="element.lockedTimes"
                                      [numDays]="settings | async | pluck:'numDays'"
                                      voidText="-"
                                      disableActions
                                      *ngIf="editable"
                                      saveOnClose
                                      (onChange)="edit(element.id, { lockedTimes: $event })">
        </app-form-field-locked-times>
        <app-form-field-locked-times-display-value [value]="element.lockedTimes"
                                                    *ngIf="! editable"
                                                    voidText="-">
        </app-form-field-locked-times-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="overlapGroup">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.overlapGroup"
                            [description]="columnDescriptionTranslationKeys.overlapGroup"
                            tutorial="31">
        </app-column-header>
      </th>
      <td mat-cell
          [class.clickable]="editable"
          *matCellDef="let element; table: table"
          (click)="editable && openOverlapGroupsDialog()">
        <div class="events-container" tabindex="0">
          @if ( ! element.overlapGroup) {
            <div class="empty" cypress="empty">
              -
            </div>
          } @else if (element.overlapGroup.coalesced?.length == 1) {
            <div cypress="alone">
              {{ 'common.alone' | translate }}
            </div>
          } @else {
            @for (x of (element.overlapGroup.coalesced | removeSelf: element); track x.to.id) {
              <div [style.background-color]="(x.to.color ?? defaultEventColor) + '20'" class="box overlap-group-div">
                {{ x.to | displayName }}
              </div>
            }
          }
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="intervals">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.intervals"
                            [description]="columnDescriptionTranslationKeys.intervals"
                            tutorial="3"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'intervals':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>

        <app-form-field-intervals [value]="element.intervals"
                                  [numDays]="settings | async | pluck:'numDays'"
                                  [voidText]="(settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd"
                                  disableActions
                                  [availableDays]="element.days"
                                  *ngIf="editable"
                                  [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd }"
                                  saveOnClose
                                  (onChange)="edit(element.id, { intervals: $event })">
        </app-form-field-intervals>
        <app-form-field-intervals-display-value [value]="element.intervals"
                                                [numDays]="settings | async | pluck:'numDays'"
                                                *ngIf="! editable"
                                                [voidText]="(settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd">
        </app-form-field-intervals-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.period"
                            [description]="columnDescriptionTranslationKeys.period"
                            tutorial="27"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'period':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <app-form-field-period [value]="element.period ?? null"
                                inherit
                                [voidText]="((settings | async)?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst"
                                [setVoidText]="'common.useDefaultValue' | translate:{ value: ((settings | async)?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst }"
                                disableActions
                                *ngIf="editable"
                                saveOnClose
                                (onChange)="edit(element.id, { period: $event })">
        </app-form-field-period>
        <app-form-field-period-display-value [value]="element.period"
                                              *ngIf="! editable"
                                              [voidText]="((settings | async)?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst">
        </app-form-field-period-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="centerOfAttraction">
      <th mat-header-cell *matHeaderCellDef>
        <app-column-header [title]="columnTitleTranslationKeys.centerOfAttraction" [description]="columnDescriptionTranslationKeys.centerOfAttraction"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table"
          [class.value-overridden]="element.events | hasValue:'centerOfAttraction':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <app-form-field-center-of-attraction [value]="element.centerOfAttraction ?? null"
                                              [preselected]="(settings | async)?.potentialCenter"
                                              [voidText]="(settings | async)?.potentialCenter"
                                              [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.potentialCenter }"
                                              disableActions
                                              *ngIf="editable"
                                              saveOnClose
                                              (onChange)="edit(element.id, { centerOfAttraction: $event })">
        </app-form-field-center-of-attraction>
        <app-form-field-center-of-attraction-display-value [value]="element.centerOfAttraction"
                                                            *ngIf="! editable"
                                                            [voidText]="(settings | async)?.potentialCenter">
        </app-form-field-center-of-attraction-display-value>
      </td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef class="unset-min-width">
        <app-column-header [title]="columnTitleTranslationKeys.color" [description]="columnDescriptionTranslationKeys.color"></app-column-header>
      </th>
      <td mat-cell *matCellDef="let element; table: table" class="unset-min-width"
          [class.value-overridden]="element.events | hasValue:'color':(onDataChange | async)">
        <div class="notice">
          <div class="middle" matTooltip="{{ 'tables.courses.values.overridden' | translate }}"></div>
        </div>
        <app-form-field-color (onChange)="edit(element.id, { color: $event })"
                              *ngIf="editable"
                              [value]="element.color ?? defaultEventColor">
        </app-form-field-color>
        <app-form-field-color-display-value [color]="element.color ?? defaultEventColor"
                                            *ngIf="! editable">
        </app-form-field-color-display-value>
      </td>
    </ng-container>


    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; table: table">
        <div class="flex-align-center">

          @if (appFeatures.CREATE_COURSES | hasAppFeature) {
            <button mat-icon-button
                    [disabled]=" ! editable"
                    type="button"
                    (click)="copy(element, 'displayName', 'days', 'tags', 'minBreakLength', 'durationVariance', 'intervals', 'teachers', 'groups', 'participants', 'locations', 'subject', 'color', 'potentialCenter', 'period', 'centerOfAttraction')">
              <mat-icon class="royal-icon"
                        matTooltip="{{ 'common.copy' | translate }}">
                content_copy
              </mat-icon>
            </button>
          }

          <button mat-icon-button
                  [disabled]=" ! editable"
                  type="button"
                  class="delete"
                  (click)="deleteOne(element.id)"
                  matTooltip="{{ 'common.delete' | translate }}">
            <mat-icon class="royal-icon">delete_outline</mat-icon>
          </button>
        </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element; table: table" [attr.colspan]="columns.length" [class.expanded]="element?.id == expandedElement()?.id && element.events?.length">
        <div class="expand-wrapper"
              [@detailExpand]="(element?.id == expandedElement()?.id && element.events?.length) ? 'expanded' : 'collapsed'">
          <div class="expand-content"
                [style.height.px]="56 + (element.events?.length ?? 0)*52">
            <div class="inset-shadow top"></div>
            <div class="inset-shadow bottom"></div>
            <div class="event-table-wrapper">
              <ng-template [eventTable]="element.id"
                          [selected]="element?.id == expandedElement()?.id"
                          [editable]="editable"
                          [course]="element.id"
                          [did]="did">
              </ng-template>
            </div>
          </div>
        </div>
      </td>
    </ng-container>


    <!----------------------------------------------------------------------->
    <!------------------------------ BULK EDIT ------------------------------>
    <!----------------------------------------------------------------------->
    <ng-container matColumnDef="bulk-select">
      <th mat-header-cell *matHeaderCellDef class="sticky">
        <div class="collapsible">
          <div class="corner top"></div>
          <div class="corner bottom"></div>
          <div class="selected-count">
            {{ selection.length }} <!-- {{ 'common.selected' | translate }} -->
          </div>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-displayName">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell">
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-subject">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <!-- <div class="selected-count"> -->
            <app-form-field-text [value]="subjectBulkValue"
                                  saveOnFocusLost
                                  *ngIf="editable"
                                  (onChange)="editMany(selection.selected, { subject: $event })">
            </app-form-field-text>
          <!-- </div> -->
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-events">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible flex justify-end">

          @if (appFeatures.CREATE_EVENTS | hasAppFeature) {
            <button mat-icon-button
                    [disabled]=" ! editable"
                    type="button"
                    (click)="createEvents(selection.selection, (preferences.durationSets.at(0) ?? defaultDurationSet))"
                    matTooltip="{{ 'common.add_event' | translate:{ 'event-durations': (preferences.durationSets.at(0) ?? defaultDurationSet).join(', ') } }}">
              <mat-icon class="royal-icon">post_add</mat-icon>
            </button>
            <button mat-icon-button
                    [disabled]=" ! editable"
                    class="more"
                    type="button"
                    [matMenuTriggerFor]="bulkEventDurationMenu"
                    matTooltip="{{ 'common.add_event_advanced' | translate }}">
              <mat-icon class="royal-icon">more_vert</mat-icon>
            </button>

            <mat-menu #bulkEventDurationMenu="matMenu"
                      class="select-event-duration">
              <button mat-menu-item
                      class="events-container"
                      (click)="createEvents(selection.selection, durationSet)"
                      *ngFor="let durationSet of preferences.durationSets; let index = index"
                      [class.selected]="index == 0">
                <span class="box event-div"
                      [style.width.px]="(duration ?? 10) * 0.7"
                      *ngFor="let duration of durationSet">{{ duration }}</span>
                <span class="summed-duration"
                      *ngIf="durationSet.length > 1"
                >
                  ({{ durationSet | sum }})
                </span>
              </button>
              <button mat-menu-item (click)="otherEventDuration(selection.selection)">
                {{ 'common.other' | translate }}
              </button>
            </mat-menu>
          }

        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-plannedDuration">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">

          <app-form-field-planned-duration
            [value]="plannedDurationBulkValue"
            [numberOfWeeksInPeriod]="periodBulkValue === undefined ? null : (numWeeksPerPeriod$ | async | get:(periodBulkValue?.id ?? (settings | async)?.period?.id ?? undefined))"
            nullable
            nullifyEmpty
            saveOnClose
            (onChange)="editMany(selection.selected, { plannedDuration: $event })"
          >
          </app-form-field-planned-duration>

        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-eventDurationVariance">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-text [value]="eventDurationVarianceBulkValue"
                      saveOnFocusLost
                      nullifyEmpty
                      validate="multiple-of-five"
                      maxWidth="2em"
                      class="push-suffix-left"
                      [voidText]="(settings | async)?.defaultEventDurationVariance"
                      prefix="±"
                      [suffix]="'common.minutes' | translate | lowercase"
                      (onChange)="editMany(selection.selected, { eventDurationVariance: toNumber($event) })">
          </app-form-field-text>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-participants">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <div formField="persons"
            collection="courses"
            path="participants"
            [value]="participantsBulkValue"
            [editable]="editable"
            [id]="selection.selected"
          >
          </div>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-groups">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <div formField="groups-persons"
            collection="courses"
            path="groups"
            [value]="groupsBulkValue"
            [editable]="editable"
            [id]="selection.selected"
            [coalescedValue]="participantsBulkValue"
            coalescedPath="participants"
          >
          </div>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-teachers">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <div formField="teachers"
            collection="courses"
            path="teachers"
            [value]="teachersBulkValue"
            [editable]="editable"
            [id]="selection.selected"
          >
          </div>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-locations">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <div formField="available-locations"
            collection="courses"
            path="locations"
            [id]="selection.selected"
            [value]="locationsBulkValue"
            [config]="availableLocationsConfig"
          ></div>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-overlapGroup">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible flex-align-center">

          <button mat-icon-button
            [matTooltip]="'tables.courses.action.connect-courses' | translate"
            [disabled]="canConnect$ | async | not"
            (click)="connectCourses(selection.selection)"
          >
            <mat-icon>link</mat-icon>
          </button>
          <button mat-icon-button
            [matTooltip]="'tables.courses.action.disconnect-courses' | translate"
            [disabled]="canDisconnect$ | async | not"
            (click)="disconnectCourses(selection.selection)"
          >
            <mat-icon>link_off</mat-icon>
          </button>

        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-days">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-available-days [value]="daysBulkValue"
                                          [numDays]="settings | async | pluck:'numDays'"
                                          voidText="-"
                                          (onChange)="editMany(selection.selected, { days: $event })"
                                          saveOnClose
                                          disableActions>
          </app-form-field-available-days>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-lockedTimes">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-locked-times [value]="lockedTimesBulkValue"
                                      [numDays]="settings | async | pluck:'numDays'"
                                      voidText="-"
                                      omitId
                                      disableActions
                                      saveOnClose
                                      (onChange)="editMany(selection.selected, { lockedTimes: $event })">
          </app-form-field-locked-times>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-minBreakLength">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-break-length [value]="minBreakLengthBulkValue"
                                      coalesced
                                      disableActions
                                      saveOnClose
                                      voidText="-"
                                      [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.defaultEventMinimumBreakLength + ' ' + ('common.minutes' | translate | lowercase) }"
                                      (onChange)="editMany(selection.selected, { minBreakLength: $event })">
          </app-form-field-break-length>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-intervals">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">

          <app-form-field-intervals [value]="intervalsBulkValue"
                                    [numDays]="settings | async | pluck:'numDays'"
                                    voidText="-"
                                    disableActions
                                    saveOnClose
                                    [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.dayStart + ' - ' + (settings | async)?.dayEnd }"
                                    (onChange)="editMany(selection.selected, { intervals: $event })">
          </app-form-field-intervals>
        </div>
      </th>
    </ng-container>


    <ng-container matColumnDef="bulk-period">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-period [value]="periodBulkValue"
                                  inherit
                                  voidText="-"
                                  [setVoidText]="'common.useDefaultValue' | translate:{ value: ((settings | async)?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst }"
                                  disableActions
                                  saveOnClose
                                  (onChange)="editMany(selection.selected, { period: $event })">
          </app-form-field-period>
        </div>
      </th>
    </ng-container>


    <ng-container matColumnDef="bulk-centerOfAttraction">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-center-of-attraction [value]="centerOfAttractionBulkValue"
                                                [preselected]="(settings | async)?.potentialCenter"
                                                voidText="-"
                                                [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.potentialCenter }"
                                                disableActions
                                                saveOnClose
                                                (onChange)="editMany(selection.selected, { centerOfAttraction: $event })">
          </app-form-field-center-of-attraction>
        </div>
      </th>
    </ng-container>


    <ng-container matColumnDef="bulk-color">
      <th mat-header-cell *matHeaderCellDef class="unset-min-width">
        <div class="collapsible">
          <app-form-field-color [value]="colorBulkValue"
                                coalesced
                                (onChange)="editMany(selection.selected, { color: $event })">
          </app-form-field-color>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-ids">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell"></th>
    </ng-container>

    <ng-container matColumnDef="bulk-createdAt">
      <th mat-header-cell *matHeaderCellDef class="disabled-cell"></th>
    </ng-container>

    <ng-container matColumnDef="bulk-tags">
      <th mat-header-cell *matHeaderCellDef>
        <div class="collapsible">
          <app-form-field-tags
            [value]="tagsBulkValue"
            [disabled]=" ! editable"
            [tagOptions]="tags$ | async"
            nullable
            saveOnClose
            (onChange)="bulkUpdateTags(selection.selection, $event)"
          >
          </app-form-field-tags>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="bulk-actions">
      <th mat-header-cell *matHeaderCellDef class="sticky">
        <div class="collapsible flex-align-center">
          <div class="border"></div>
          <div class="corner top"></div>
          <div class="corner bottom"></div>
          <span class="spacer"></span>
          <button mat-icon-button
                  [disabled]=" ! editable"
                  type="button"
                  class="bulk-delete"
                  (click)="deleteMany(selection.selected)">
            <mat-icon class="royal-icon"
                      matTooltip="{{ 'common.delete' | translate }}"
                      [matBadge]="selection.length"
                      matBadgeOverlap="true"
                      matBadgeColor="warn"
                      aria-hidden="false">delete_outline</mat-icon>
          </button>
        </div>
      </th>
    </ng-container>

    <!-- a column that absorbs width if for example no column is visible -->
    <!-- the colspan=2 also fixes the stickyEnd bug where columns are positioned above the table header -->
    <ng-container matColumnDef="absorber">
      <!-- <th mat-header-cell *matHeaderCellDef colspan="2"></th> -->
      <th mat-header-cell *matHeaderCellDef colspan="1"></th>
      <td mat-cell *matCellDef="let element; table: table"></td>
    </ng-container>
    <ng-container matColumnDef="bulk-absorber">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; table: table"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>

    <!-- bulk edit table header -->
    <tr mat-header-row *matHeaderRowDef="bulkColumns;"
        class="bulk" [class.hidden]="selection.empty" [class.animate]="dataSource.loading$ | async | not"></tr>


    <!-- if no color is set the royal purple will be used -->
    <tr mat-row
        class="table-row"
        [style.--bg-color]="row.color ?? row.course?.color ?? defaultEventColor"
        [class.example-expanded-row]="expandedElement === row"
        *matRowDef="let row; columns: columns;">
    </tr>

    <tr mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="expand-row">
    </tr>
  </table>
</div>

@if (paginator) {
  <mat-paginator showFirstLastButtons
                  [length]="dataSource ? (dataSource.totNumDocs$ | async) : 0"
                  [pageIndex]="0"
                  [pageSize]="pageSize"
                  [pageSizeOptions]="pageSizes">
  </mat-paginator>
}
