import { animate,
         style,
         transition,
         trigger               } from '@angular/animations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component,
         Input                 } from '@angular/core';


@Component({
    selector: 'app-no-columns',
    templateUrl: './no-columns.component.html',
    styleUrls: ['./no-columns.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':leave', [
                style({ opacity: 1 }),
                animate('120ms', style({ opacity: 0 }))
            ]),
            transition(':enter', [
                style({ opacity: 0 }),
                animate('80ms', style({ opacity: 1 }))
            ])
        ])
    ],
    standalone: false
})
export class NoColumnsComponent {
  @Input()
  set visible (value: boolean | string | null | undefined) { this._visible = coerceBooleanProperty(value); }
  get visible () { return this._visible; }
  private _visible = false;
}
