import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';
import { NgPipesModule                           } from 'ngx-pipes';

import { AppCommonModule                         } from 'app/common/common.module';
import { TranslationModule                       } from 'app/core/translate/translate.module';
import { SharedDirectivesModule                        } from 'app/shared/directives/directives.module';
import { SharedPipesModule                             } from 'app/shared/pipes/pipes.module';
import { SharedFormsModule      as AppFormsModule      } from 'app/shared/forms/forms.module';
import { SharedComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';

import { ComponentsModule                        } from '../components/components.module';
import { GroupsComponent                         } from './groups.component';

import { GroupTypePipe                           } from './groups.pipe';
import { CustomSearchComponent } from '../components/custom-search/custom-search.component';
import { HasAppFeaturePipe } from "../../../../core/environment/environment.pipe";
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { FormFieldsDirective } from '@app/shared/form-fields/form-fields.directive';
import { BreakLengthComponent } from '@app/shared/form-fields/break-length/break-length.component';
import { DisplayValueComponent as BreakLengthDisplayValueComponent } from '@app/shared/form-fields/break-length/components/display-value/display-value.component';
import { AvailableDaysComponent } from '@app/shared/form-fields/available-days/available-days.component';
import { DisplayValueComponent as AvailableDaysDisplayValueComponent } from '@app/shared/form-fields/available-days/components/display-value/display-value.component';
import { DynamicLockedTimesComponent } from '@app/shared/form-fields/dynamic-locked-times/dynamic-locked-times.component';
import { DisplayValueComponent as DynamicLockedTimesDisplayValueComponent } from '@app/shared/form-fields/dynamic-locked-times/components/display-value/display-value.component';
import { IntervalsComponent } from '@app/shared/form-fields/intervals/intervals.component';
import { DisplayValueComponent as IntervalsDisplayValueComponent } from '@app/shared/form-fields/intervals/components/display-value/display-value.component';
import { LockedTimesComponent } from '@app/shared/form-fields/locked-times/locked-times.component';
import { DisplayValueComponent as LockedTimesDisplayValueComponent } from '@app/shared/form-fields/locked-times/components/display-value/display-value.component';
import { TagsComponent } from '@app/shared/form-fields/tags/tags.component';
import { DisplayValueComponent as TagsDisplayValueComponent } from '@app/shared/form-fields/tags/components/display-value/display-value.component';
import { RootIntervalsComponent } from '@app/shared/form-fields/root-intervals/root-intervals.component';
import { DisplayValueComponent as RootIntervalsDisplayValueComponent } from '@app/shared/form-fields/root-intervals/components/display-value/display-value.component';
import { TextComponent } from '@app/shared/form-fields/text/text.component';
import { SearchComponent } from '@app/shared/components/search/search.component';
import { TypeSafeMatCellDefDirective } from '@app/shared/directives/type-safe-mat-cell-def/type-safe-mat-cell-def.directive';

@NgModule({
  declarations: [
    GroupsComponent,
    GroupTypePipe
  ],
  exports: [
    GroupsComponent
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    AppCommonModule,
    TranslationModule,
    SharedDirectivesModule,
    SharedPipesModule,
    AppFormsModule,
    AppComponentsModule,
    ComponentsModule,
    SearchComponent,
    CustomSearchComponent,
    HasAppFeaturePipe,
    LoadingComponent,
    FormFieldsDirective,
    TextComponent,
    BreakLengthComponent, BreakLengthDisplayValueComponent,
    AvailableDaysComponent, AvailableDaysDisplayValueComponent,
    DynamicLockedTimesComponent, DynamicLockedTimesDisplayValueComponent,
    IntervalsComponent, IntervalsDisplayValueComponent,
    LockedTimesComponent, LockedTimesDisplayValueComponent,
    TagsComponent, TagsDisplayValueComponent,
    RootIntervalsComponent, RootIntervalsDisplayValueComponent,
    TypeSafeMatCellDefDirective
  ]
})
export class GroupsModule { }
