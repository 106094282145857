import { Component,
         Inject,
         OnDestroy                    } from '@angular/core';
import { FormArray,
         FormControl,
         FormGroup                    } from '@angular/forms';
import { MatDialogRef,
         MAT_DIALOG_DATA              } from '@angular/material/dialog';
import { BehaviorSubject,
         debounceTime,
         map,
         Observable,
         shareReplay,
         startWith,
         Subject                      } from 'rxjs';
import $                                from 'jquery';

import { EnvironmentService,
         SourceService,
         UserPreferencesService       } from '@app/core';
import { Populated as P               } from '@app/shared/interfaces';
import { Util                         } from '@app/common';
import { COLLECTION                   } from '../../constants';


type Entity     = P.group | P.teacher | P.location | P.person;
type Collection = Extract<Util.Types.Collection, 'groups' | 'teachers' | 'locations' | 'persons'>;


export type Data = {
  did: string;
}

export type EntityForm = FormGroup<{
  species:     FormControl<string | null>;
  displayName: FormControl<string | null>;
  firstName:   FormControl<string | null>;
  lastName:    FormControl<string | null>;
  ids:         FormControl<string | null>;
}>;

@Component({
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss'],
    standalone: false
})
export class CreateComponent implements OnDestroy {
  private readonly onDestroy = new Subject<void>();
  private readonly did: string;

  protected readonly title: string;
  protected readonly columns      = new BehaviorSubject<string[]>([]);
  protected readonly formArray    = new FormArray<EntityForm>([])
  protected readonly numEntities  = new Observable<number>();

  constructor (
    @Inject(COLLECTION) protected collection: Collection,
    private   _source:      SourceService,
    private   _dialogRef:   MatDialogRef<CreateComponent>,
    private   _environment: EnvironmentService,
    protected preferences:  UserPreferencesService,
    @Inject(MAT_DIALOG_DATA)
    { did }: Data
  ) {
    this.did        = did;
    this.collection = collection;

    if      (this.collection === 'groups')    this.title = 'common.create_group';
    else if (this.collection === 'teachers')  this.title = 'common.create_teacher';
    else if (this.collection === 'locations') this.title = 'common.create_location';
    else if (this.collection === 'persons')   this.title = 'common.create_person';

    this.numEntities = this.formArray.valueChanges
    .pipe(
      debounceTime(100),
      map(x => x
        .filter(x => Object.values(x).some(x => Array.isArray(x) ? x.length : !! x))
        .length
      ),
      startWith(0),
      shareReplay(1)
    );

    // initialize the first form after we have subscribed to onFormChange
    this.formArray.push(this.initializeEmptyForm());

    // figure out what columns to show
    let columns: string[] = [];
    if (this.collection === 'persons')     columns = ['firstName', 'lastName'];
    else if (this._environment.organizationType === 'school' && this.collection === 'groups')
                                           columns = ['species', 'displayName'];
    else                                   columns = ['displayName'];
    if (preferences.displayPublicId)       columns.push('ids');
    this.columns.next(columns);
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private initializeEmptyForm (): EntityForm {
    return new FormGroup({
      species:     new FormControl<string | null>('class'),
      displayName: new FormControl<string | null>(null),
      firstName:   new FormControl<string | null>(null),
      lastName:    new FormControl<string | null>(null),
      ids:         new FormControl<string | null>(null)
    });
  }

  protected paste (key: keyof EntityForm['controls'], event: ClipboardEvent, index: number) {
    // there must be clipboard data
    const pastedText = event.clipboardData?.getData('text/plain');
    if ( ! pastedText) return;

    // the form value must be empty
    if (this.formArray.at(index).value[key]) return;

    // there must be a newline in the clipboard data
    if ( ! pastedText.includes('\n')) return;
    const lines = pastedText.split('\n').map(x => x.trim()).filter(Boolean)

    // prevent the default paste action
    event.preventDefault();

    // try merge into already existing forms
    lines.forEach((line, i) => {
      // if the form exist overwrite the corresponding value, otherwise create a new form
      if ( ! this.formArray.at(index + i)) this.formArray.push(this.initializeEmptyForm());
      this.formArray.at(index + i).controls[key].setValue(line);
    });
  }

  protected remove (index: number) {
    if (this.formArray.length > 1) this.formArray.removeAt(index);
    else                           this.formArray.at(index)?.reset();
  }

  protected addOneMore (event: PointerEvent | MouseEvent) {
    this.formArray.push(this.initializeEmptyForm());

    // focus on the first input of the new form
    setTimeout(() => {
      if ( ! event.target) return;
      const target = $(event.target).closest('mat-dialog-container').find('.grid button').eq(-2).next().find('input');

      // console.log( $(event.target).closest('mat-dialog-container').find('.grid button').eq(-2), target)
      if ( ! target) return;
      target.trigger('focus');
    });
  }

  protected submit () {
    // map from formGroup to entity
    let entities: (Pick<Exclude<Entity, P.person>, 'displayName' | 'ids'> | Pick<Extract<Entity, P.person>, 'firstName' | 'lastName' | 'ids'>)[] = [];
    this.formArray.value.forEach(v => {
      entities.push({
        ...(this._environment.organizationType === 'school' && this.collection == 'groups') && {
          species: v.species
        },
        displayName: v.displayName ?? undefined,
        firstName:   v.firstName   ?? undefined,
        lastName:    v.lastName    ?? undefined,
        ids:         v.ids         ?? undefined
      });
    });

    console.log(entities);

    // remove empty forms
    entities = entities.filter(x => Object.values(x).some(Boolean));
    if (entities.length) {
      this._source.set({ did: this.did, collection: this.collection }, entities);
      this._dialogRef.close();
    }
  }
}