import { CommonModule } from '@angular/common';
import { Component,
         Input                           } from '@angular/core';

@Component({
    imports: [
        CommonModule
    ],
    selector: 'app-form-field-color-display-value',
    templateUrl: './display-value.component.html',
    styleUrls: ['./display-value.component.scss']
})
export class DisplayValueComponent {

  constructor() { }

  @Input()
  get color(): string | null |undefined { return this._color; }
  set color(value: string | null |undefined) {
    this._color = value;
  }
  private _color: string | null | undefined;

  @Input()
  get nullColor(): string | null | undefined { return this._nullColor; }
  set nullColor(value: string | null | undefined) {
    this._nullColor = value;
  }
  private _nullColor: string | null | undefined;
}
