import { BehaviorSubject, combineLatest, debounceTime, map, shareReplay, startWith, switchMap, take } from 'rxjs';
import { AfterViewInit, Component, DestroyRef, ElementRef, inject, signal, ViewChild, WritableSignal } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import $ from 'jquery';
import { EnvironmentService, SourceService } from '@app/core';
import { Util } from '@app/common';
import { Populated  as P, Serialized as S } from '@app/shared/interfaces';
import { inAnimation, inOutAnimation } from '@app/shared/animations';
import { CourseForm, FormComponent, PasteData, toMultipleOf5 } from './components/form/form.component';
import { ExcelComponent } from './components/excel/excel.component';
import { chain } from 'lodash';
import { DIVISION_ID } from '@app/constants/common';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslationModule } from '@app/core/translate/translate.module';
import { SharedPipesModule } from '@app/shared/pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { SelectComponent } from '@app/shared/form-fields/select/select.component';
import { Selected, Type } from '@app/shared/components/selection-list/util';


type _Course = Omit<S.courses, 'id' | 'belongsTo' | 'events' | 'groups' | 'teachers'> & {
  events?:   Pick<P.event, 'preferredDuration'>[];
  groups?:   { to: Pick<P.group,   'id'> }[];
  teachers?: { to: Pick<P.teacher, 'id'> }[];
};

type Select = {
  collection: Util.Types.Collection;
  label:      string;
  type:       Type,
  selected:   WritableSignal<Selected>;
};

@Component({
    templateUrl: './create-courses.component.html',
    styleUrl: './create-courses.component.scss',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatFormFieldModule,
        MatChipsModule,
        TranslationModule,
        SharedPipesModule,
        SelectComponent,
        FormComponent,
        ExcelComponent,
    ],
    animations: [
        inOutAnimation,
        inAnimation
    ]
})
export class CreateCoursesComponent implements AfterViewInit {
  private readonly _destroy   = inject(DestroyRef);
  private readonly _source    = inject(SourceService);
  private readonly _env       = inject(EnvironmentService);
  private readonly _dialogRef = inject<MatDialogRef<CreateCoursesComponent>>(MatDialogRef);
  private readonly _did       = inject(DIVISION_ID);

  protected readonly type = this._env.organizationType;

  protected readonly tabIndex = signal<0 | 1 | 2>(0);

  protected readonly manualFormArray = new FormArray<CourseForm>([this.initializeEmptyForm()]);
  protected readonly bulkFormArray   = new FormArray<CourseForm>([this.initializeEmptyForm()]);

  protected readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('entityInput') entityInput: ElementRef<HTMLInputElement>;
  protected readonly entityCtrl = new FormControl<string | P.group>('');

  protected readonly onSelectedSubjects = new BehaviorSubject<string[]>([]);

  @ViewChild(ExcelComponent) excelComponent: ExcelComponent | undefined;
  protected numExcelCourses = new BehaviorSubject<number>(0);

  private readonly _selectedPrimaryGroups   = signal<Selected>(null);
  private readonly _selectedSecondaryGroups = signal<Selected>(null);
  private readonly _selectedTeachers        = signal<Selected>(null);

  private readonly _primaryGroups   = this._source.getPopulatedGroups  ({onDestroy: this._destroy }).pipe(map(x => x.filter(x => x.species == 'class')));
  private readonly _secondaryGroups = this._source.getPopulatedGroups  ({onDestroy: this._destroy }).pipe(map(x => x.filter(x => x.species != 'class')));
  private readonly _teachers        = this._source.getPopulatedTeachers({onDestroy: this._destroy });
  protected readonly totNumOptions = combineLatest([this._primaryGroups, this._secondaryGroups, this._teachers])
    .pipe(map(([a, b, c]) => a.length + b.length + c.length));

  private readonly _trueSelectedPrimaryGroups   = combineLatest([this._primaryGroups, toObservable(this._selectedPrimaryGroups)])
    .pipe(
      map(([options, selected]) => {
        if (selected == null) return [];
        if (selected == 'all') return options;
        return options.filter(x => selected.has(x.id));
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  private readonly _trueSelectedSecondaryGroups = combineLatest([this._secondaryGroups, toObservable(this._selectedSecondaryGroups)])
    .pipe(
      map(([options, selected]) => {
        if (selected == null) return [];
        if (selected == 'all') return options;
        return options.filter(x => selected.has(x.id));
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  private readonly _trueSelectedTeachers        = combineLatest([this._teachers, toObservable(this._selectedTeachers)])
    .pipe(
      map(([options, selected]) => {
        if (selected == null) return [];
        if (selected == 'all') return options;
        return options.filter(x => selected.has(x.id));
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  protected readonly select: Select[] = [
    {
      collection: 'groups',
      label:      'common.the_primary_groups',
      type:       'primaryGroups',
      selected:   this._selectedPrimaryGroups,
    }, {
      collection: 'groups',
      label:      'common.the_secondary_groups',
      type:       'secondaryGroups',
      selected:   this._selectedSecondaryGroups,
    }, {
      collection: 'teachers',
      label:      'common.the_teachers',
      type:       'teachers',
      selected:   this._selectedTeachers,
    }
  ];



  private readonly _numSimple = this.manualFormArray.valueChanges
    .pipe(
      debounceTime(100),
      map(x => x
        .filter(x => Object.values(x).some(x => Array.isArray(x) ? x.length : !! x))
        .length
      ),
      startWith(0),
      shareReplay(1)
    );
  private readonly _numAdvanced = this.bulkFormArray.valueChanges
    .pipe(
      debounceTime(100),
      map(x => x
        .filter(x => Object.values(x).some(x => Array.isArray(x) ? x.length : !! x))
        .length
      ),
      startWith(0),
      shareReplay(1)
    );

  protected readonly numCourses = toObservable(this.tabIndex)
    .pipe(
      switchMap(x => {
        if (x == 0) return this._numSimple;

        if (x == 1) {
          if (this.type == 'school') {
            return combineLatest([this._numAdvanced, this._trueSelectedPrimaryGroups, this._trueSelectedSecondaryGroups, this._trueSelectedTeachers])
              .pipe(
                map(([rows, a, b, c]) => {
                  return rows * (a.length + b.length + c.length);
                })
              );
          } else {
            return combineLatest([this._numAdvanced, this.onSelectedSubjects]).pipe(map(([a, b]) => a * b.length));
          }
        }

        return this.numExcelCourses;
      })
    );

  ngAfterViewInit () {
    this.excelComponent?.numCourses
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe(this.numExcelCourses);
  }

  private initializeEmptyForm (): CourseForm {
    return new FormGroup({
      ids:                new FormControl<string | null>(null),
      displayName:        new FormControl<string | null>(null),
      subject:            new FormControl<string | null>(null),
      plannedDuration:    new FormControl<string | null>(null),
      events:             new FormControl<number[]>([], { nonNullable: true })
    });
  }

  protected removeSubject (val: string) {
    const selectedEntities = this.onSelectedSubjects.value;
    this.onSelectedSubjects.next(selectedEntities.filter(entity => entity != val));
  }

  protected addSubject (event: MatChipInputEvent) {
    const val = event.value.trim();
    if ( ! val) return;

    const next = chain([...this.onSelectedSubjects.value, event.value])
      .sort()
      .sortedUniq()
      .value();
    this.onSelectedSubjects.next(next);

    // reset input after selection
    this.entityInput.nativeElement.value = '';
    this.entityCtrl.setValue(null);
  }

  protected remove (array: FormArray<CourseForm>, index: number) {
    if (array.length > 1) array.removeAt(index);
    else                  array.at(index)?.reset();
  }

  protected addCourse (event: PointerEvent | MouseEvent) {
    const array = this.tabIndex() == 0 ? this.manualFormArray : this.bulkFormArray;
    array.push(this.initializeEmptyForm());

    // focus on the first input of the new form
    setTimeout(() => {
      if ( ! event.target) return;
      const target = $(event.target)
        .closest('mat-dialog-container')
        .find('mat-tab-body')
        .eq(this.tabIndex())
        .find('app-form')
        .last()
        .find('input')
        .first();
      if ( ! target) return;
      target.trigger('focus');
    });
  }

  protected createCourses (type: 'simple' | 'advanced') {
    let courses = new Array<_Course>();

    if (type == 'simple') {
      // simply add as is
      this.manualFormArray.value.forEach(v => {
        const ids                = v.ids                                                        ?? undefined;
        const displayName        = v.displayName                                                ?? undefined;
        const subject            = v.subject                                                    ?? undefined;
        const plannedDuration    = v.plannedDuration                                            ?? undefined;
        const events             = v.events?.map(x => ({ preferredDuration: x, parked: true, visible: true })) ?? undefined;   // need to set the parked and visible flag
        courses.push({ ids, displayName, subject, plannedDuration, events });
      });
    } else {
      if (this.type == 'school') {
        combineLatest([
          this._trueSelectedPrimaryGroups  .pipe(map(xs => xs.map(x => ({ collection: 'groups'   satisfies Util.Types.Collection, item: x })))),
          this._trueSelectedSecondaryGroups.pipe(map(xs => xs.map(x => ({ collection: 'groups'   satisfies Util.Types.Collection, item: x })))),
          this._trueSelectedTeachers       .pipe(map(xs => xs.map(x => ({ collection: 'teachers' satisfies Util.Types.Collection, item: x }))))
        ])
          .pipe(
            map(x => x.flat()),
            take(1),
            takeUntilDestroyed(this._destroy)
          )
          .subscribe(selection => selection.forEach(({ item: selected, collection }) => {
            this.bulkFormArray.value.forEach(row => {
              const subject            = row.subject ?? undefined;
              const displayName        = selected.displayName && subject ? `${selected.displayName} - ${subject}` : undefined;
              const groups             = collection == 'groups'   ? [{ to: { id: selected.id } }] : undefined;
              const teachers           = collection == 'teachers' ? [{ to: { id: selected.id } }] : undefined;
              const plannedDuration    = row.plannedDuration ?? undefined;
              const events             = row.events?.map(x => ({ preferredDuration: x, parked: true, visible: true })) ?? undefined;   // need to set the parked and visible flag
              courses.push({ displayName, subject, plannedDuration, events, groups, teachers });
            });
          }));
      } else {
        this.onSelectedSubjects.value.forEach(subject => {
          this.bulkFormArray.value.forEach(row => {
            const displayName        = row.displayName     ?? undefined;
            const plannedDuration    = row.plannedDuration ?? undefined;
            const events             = row.events?.map(x => ({ preferredDuration: x, parked: true, visible: true })) ?? undefined;   // need to set the parked and visible flag
            courses.push({ displayName, subject, plannedDuration, events });
          });
        });
      }
    }

    // remove empty forms
    courses = courses.filter(x => Object.values(x).some(x => Array.isArray(x) ? x.length : !! x));
    if (courses.length) {
      this._source.set({ did: this._did, collection: 'courses'}, courses);
      this._dialogRef.close();
    }
  }

  protected paste (array: FormArray<CourseForm>,  index: number, { key, values}: PasteData) {
    // try merge into already existing forms
    values.forEach((str, i) => {
      // if the form exist overwrite the corresponding value, otherwise create a new form
      if ( ! array.at(index + i)) array.push(this.initializeEmptyForm());
      if (key == 'events') {
        // need to parse the string into an array of numbers
        const arr = str.split(',').map(x => toMultipleOf5(x)).filter(Boolean);
        array.controls[index + i].controls[key].setValue(arr);
      } else {
        array.controls[index + i].controls[key].setValue(str);
      }
    });
  }

  protected submit () {
    if (this.tabIndex() == 0) return this.createCourses('simple');
    if (this.tabIndex() == 1) return this.createCourses('advanced');
    this.excelComponent?.submit();
    this._dialogRef.close();
  }

}