import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map, filter, takeUntil } from 'rxjs/operators';
import { DivisionSettings, PartialTags, Populated } from 'app/shared/interfaces';
import { sourceSelectPipe } from 'app/core';
import { Options as formOptions } from 'app/shared/forms/locations/locations.component';
import { LocationsComponent as Form } from 'app/shared/forms/locations/locations.component';
import { SelectionService } from 'app/shared/services/selection/selection.service';
import { Collection } from '../types';
import { COLLECTION } from '../constants';
import { TableCore } from '../table-core';
import { TableColumnsService } from '../services/table-columns/table-columns.service';
import { CustomSearchService } from '../services/custom-search/custom-search.service';
import { StateService } from '../services/state/state.service';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';


const collection = 'locations' satisfies Collection;
type RowType = Populated.location;

@Component({
    selector: 'app-locations-table',
    templateUrl: './locations.component.html',
    styleUrl: './locations.component.scss',
    providers: [
        { provide: COLLECTION, useValue: collection },
        DataSourceService,
        SelectionService,
        TableColumnsService,
        CustomSearchService,
        StateService,
    ],
    standalone: false
})
export class LocationsComponent extends TableCore<typeof collection, RowType> implements AfterViewInit {

  @ViewChild(Form)
  protected form?: Form;
  protected formOptions: formOptions = {
    showIds:         false,
    showDisplayName: true
  };

  protected settings: Observable<DivisionSettings>;

  // bulk edit values
  protected daysBulkValue:           null | undefined | NonNullable<Populated.location['days']>;
  protected lockedTimesBulkValue:    null | undefined | NonNullable<Populated.location['lunch']>;
  protected minBreakLengthBulkValue: null | undefined | NonNullable<Populated.location['minBreakLength']>;
  protected tagsBulkValue:           null | undefined | PartialTags;

  constructor () {
    super();
  }

  ngAfterViewInit () {
    super.ngAfterViewInit();

    this.afterSourceGroupBy(
      ['locations', 'settings'],
      [],
      this._source.getLocationsNew
    )
    .then(() => {
      this.dataSource.init({ source: this.data });
      this.selection.dataSource = this.dataSource;

      this.settings = this._source.getStrictSettings({ did: this.did, onDestroy: this.onDestroy });

      this.subscribeToSelection();
    });
  }

  // subscribes to selection events
  private subscribeToSelection () {
    this._source
    .getPopulatedLocations({ did: this.did, skipNoFilter: true, onDestroy: this.onDestroy },
      sourceSelectPipe(
        this.selection.onSelection().pipe(map(x => ({ 'id': x.map(y => y.id) })))
      ))
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      debounceTime(200)
    )
    .subscribe(xs => {
      this.daysBulkValue           = TableCore.isSame(xs.map(x => x.days           ?? []), ['day', 'rank']);
      this.lockedTimesBulkValue    = TableCore.isSame(xs.map(x => x.lockedTimes    ?? []), ['start', 'end', 'displayName']);
      this.minBreakLengthBulkValue = TableCore.isSame(xs.map(x => x.minBreakLength ?? null));
      this.tagsBulkValue           = TableCore.getSelectedTags(xs);
    });
  }
}