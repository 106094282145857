import { Component, AfterViewInit, ChangeDetectionStrategy, inject, signal } from '@angular/core';
import { animate, state, stagger, query, style, transition, trigger } from '@angular/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map, takeUntil, filter, debounceTime } from 'rxjs/operators';
import { chain, uniqBy } from 'lodash';
import { nanoid } from 'nanoid';
import { Populated, DivisionSettings, PartialTags, Species, Coalesced } from 'app/shared/interfaces';
import { TranslateService, sourceSelectPipe, } from 'app/core';
import { SelectionService } from 'app/shared/services/selection/selection.service';
import { commonConstants, } from 'app/constants/common';
import { autoLink } from 'app/shared/dialogs/parallel-courses/components/events/utils';
import { ChangeEvent } from 'app/core/source/core/types';
import { Collection } from '../types';
import { COLLECTION, COURSE_TABLE_COLUMNS } from '../constants';
import { TableCore } from '../table-core';
import { TableColumnsService } from '../services/table-columns/table-columns.service';
import { CustomSearchService } from '../services/custom-search/custom-search.service';
import { StateService } from '../services/state/state.service';
import { CreateCoursesComponent } from '../components/create-courses/create-courses.component';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';
import { ScheduleRangeService } from '@app/shared/services/schedule-range/schedule-range.service';
import { AvailableLocationConfig } from '@app/shared/form-fields/form-fields.types';

const collection = 'courses' satisfies Collection;
type RowType = Populated.course;

@Component({
    selector: 'app-courses-table',
    templateUrl: './courses.component.html',
    styleUrl: './courses.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: COLLECTION, useValue: collection },
        DataSourceService,
        SelectionService,
        TableColumnsService,
        CustomSearchService,
        StateService,
        { provide: COURSE_TABLE_COLUMNS, useExisting: TableColumnsService }
    ],
    animations: [
        trigger('detailExpand', [
            state('*', style({ height: '0px', minHeight: '0' })),
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('240ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('enterAnimation', [
            transition(':leave', [
                query(':leave', [
                    style({ height: '200px' }), stagger(1000, [
                        animate('20ms', style({ height: '0px' }))
                    ])
                ])
            ])
        ])
    ],
    standalone: false
})
export class CoursesComponent extends TableCore<typeof collection, RowType> implements AfterViewInit {
  private readonly _translate     = inject(TranslateService);
  private readonly _scheduleRange = inject(ScheduleRangeService);

  protected teachers:        Observable<Populated.teacher[]>;
  protected persons:         Observable<Populated.person[]>;
  protected groups:          Observable<Populated.group[]>;
  protected locations:       Observable<Populated.location[]>;
  protected periods:         Observable<Populated.period[]>;
  protected settings:        Observable<DivisionSettings>;

  protected readonly expandedElement = signal<Populated.course | null>(null);

  private readonly courses$          = new BehaviorSubject<Populated.course[]>([]);
  private readonly _selectedCourses$ = new BehaviorSubject<Populated.course[]>([]);

  // can connect as long as there are at least two courses selected
  protected readonly canConnect$ = this._selectedCourses$.pipe(map(courses => courses.length > 1))
  // can disconnect as long as there is at least one course with an overlap group
  protected readonly canDisconnect$ = this._selectedCourses$.pipe(map(courses => courses.some(x => x.overlapGroup)));

  protected numWeeksPerPeriod$ = new BehaviorSubject<Map<string | undefined, number> >(new Map());

  protected defaultEventColor = commonConstants.COLORS.EVENT_DEFAULT;

  readonly defaultDurationSet = [60]

  // used to rerender overridden value indications
  protected onDataChange: Observable<ChangeEvent<Populated.course>>;

  // bulk edit values
  protected subjectBulkValue:               null | undefined | NonNullable<Populated.course['subject']>;
  protected plannedDurationBulkValue:       null | undefined | NonNullable<Populated.course['plannedDuration']>;
  protected eventDurationVarianceBulkValue: null | undefined | NonNullable<Populated.course['eventDurationVariance']>;
  protected participantsBulkValue:          null | undefined | NonNullable<Populated.course['participants']>;
  protected groupsBulkValue:                null | undefined | NonNullable<Populated.course['groups']>;
  protected teachersBulkValue:              null | undefined | NonNullable<Populated.course['teachers']>;
  protected locationsBulkValue:             null | undefined | NonNullable<Populated.course['locations']>;
  protected daysBulkValue:                  null | undefined | NonNullable<Populated.course['days']>;
  protected intervalsBulkValue:             null | undefined | NonNullable<Populated.course['intervals']>;
  protected periodBulkValue:                null | undefined | NonNullable<Populated.course['period']>;
  protected centerOfAttractionBulkValue:    null | undefined | NonNullable<Populated.course['centerOfAttraction']>;
  protected lockedTimesBulkValue:           null | undefined | NonNullable<Populated.course['lockedTimes']>;
  protected minBreakLengthBulkValue:        null | undefined | NonNullable<Populated.course['minBreakLength']>;
  protected colorBulkValue:                 null | undefined | NonNullable<Populated.course['color']>;
  protected tagsBulkValue:                  null | undefined | PartialTags;

  constructor () {
    // need to inject the following so its present in the dependency tree when the event table is loaded
    inject(COURSE_TABLE_COLUMNS);

    super();

    this._scheduleRange.numWeeksPerPeriod$()
    .pipe(takeUntilDestroyed())
    .subscribe(this.numWeeksPerPeriod$);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.afterSourceGroupBy(
      ['settings', 'courses', 'events', 'groups', 'teachers', 'persons', 'locations', 'overlapGroups', 'periods'],
      ['events', 'overlapGroups'],
      this._source.getCoursesNew
    )
    .then(() => {

      // merge events into the courses in order to determine if values have been overridden
      const data = combineLatest({
        courses:   this.data,
        eventsMap: this._source.getPopulatedEvents({ did: this.did, onDestroy: this.onDestroy })
          .pipe(map(events => new Map(events.map(x => [x.id, x]))))
      })
      .pipe(
        map(({ courses, eventsMap }) => {
          courses?.docs.forEach(c => c.events = c.events?.map(x => eventsMap.get(x.id) ?? x));
          return courses;
        })
      );

      this.dataSource.init({ source: data });
      this.selection.dataSource = this.dataSource;

      this.settings  = this._source.getStrictSettings    ({ did: this.did, onDestroy: this.onDestroy });
      this.persons   = this._source.getPopulatedStudents ({ did: this.did, onDestroy: this.onDestroy });
      this.teachers  = this._source.getPopulatedTeachers ({ did: this.did, onDestroy: this.onDestroy });
      this.groups    = this._source.getPopulatedGroups   ({ did: this.did, onDestroy: this.onDestroy });
      this.locations = this._source.getPopulatedLocations({ did: this.did, onDestroy: this.onDestroy });
      this.periods   = this._source.getPopulatedPeriods  ({ did: this.did, onDestroy: this.onDestroy });

      // get the populated courses as we need to access all, not just the ones displayed in the table (this.data)
      this._source.getPopulatedCourses({ did: this.did, onDestroy: this.onDestroy })
      .pipe(takeUntil(this.onDestroy))
      .subscribe(this.courses$);

      // must have the up to date version of the selected courses
      combineLatest({
        courses:  this.courses$,
        selected: this.selection.onSelected$
      })
      .pipe(
        map(({ courses, selected }) => selected
          .map(x => courses.find(y => y.id === x.id))
          .filter(Boolean)
        ),
        takeUntil(this.onDestroy)
      )
      .subscribe(x => this._selectedCourses$.next(x));

      // to rerender overridden value notice
      this.onDataChange = this._source.onCoursesChange<Populated.course>({ did: this.did, onDestroy: this.onDestroy });

      this.subscribeToSelection();
    })
    .catch(() => { })
  }

  // subscribes to selection events
  private subscribeToSelection () {
    this._source
    .getPopulatedCourses({ did: this.did, skipNoFilter: true, onDestroy: this.onDestroy },
      sourceSelectPipe(
        this.selection.onSelection().pipe(map(x => ({ 'id': x.map(y => y.id) })))
      ))
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      debounceTime(200)
    )
    .subscribe(xs => {
      this.subjectBulkValue               = TableCore.isSame(xs.map(x => x.subject               ?? null));
      this.plannedDurationBulkValue       = TableCore.isSame(xs.map(x => x.plannedDuration       ?? null));
      this.eventDurationVarianceBulkValue = TableCore.isSame(xs.map(x => x.eventDurationVariance ?? null));
      this.participantsBulkValue          = TableCore.isSame(xs.map(x => x.participants          ?? []));
      this.groupsBulkValue                = TableCore.isSame(xs.map(x => x.groups                ?? []));
      this.teachersBulkValue              = TableCore.isSame(xs.map(x => x.teachers              ?? []));
      this.locationsBulkValue             = TableCore.isSame(xs.map(x => x.locations             ?? []));
      this.daysBulkValue                  = TableCore.isSame(xs.map(x => x.days                  ?? []), ['day', 'rank']);
      this.intervalsBulkValue             = TableCore.isSame(xs.map(x => x.intervals             ?? []), ['start', 'end']);
      this.periodBulkValue                = TableCore.isSame(xs.map(x => x.period                ?? null), ['id']);
      this.centerOfAttractionBulkValue    = TableCore.isSame(xs.map(x => x.centerOfAttraction    ?? null));
      this.lockedTimesBulkValue           = TableCore.isSame(xs.map(x => x.lockedTimes           ?? []), ['start', 'end', 'displayName']);
      this.minBreakLengthBulkValue        = TableCore.isSame(xs.map(x => x.minBreakLength        ?? null));
      this.colorBulkValue                 = TableCore.isSame(xs.map(x => x.color                 ?? this.defaultEventColor));
      this.tagsBulkValue                  = TableCore.getSelectedTags(xs);
    })
  }

  protected getEventTooltip(course: Populated.course, event: Populated.event): string {
    const name:     string = event?.displayName ?? course.displayName ?? course.ids!;
    const duration: string = `${ event?.duration } ${ this._translate.instant('common.minutes') }`;
    return `${ name } \n\n ${ duration }`;
  }

  protected createEvents (courses: { id: string }[], durationSet: number[]) {
    const events: { course: string, preferredDuration: number }[] = []
    // set
    courses.forEach(course => {
      durationSet.forEach(preferredDuration => {
        events.push({ course: course.id, preferredDuration });
      });
    });

    this._source.set({ collection: 'events', did: this.did }, events);

    // try update duration sets list to make the most recent item the first and therefore the default
    this.preferences.addDurationSet(durationSet);
  }

  protected otherEventDuration (courses: { id: string }[]) {
    this._dialog
    .openAddDurationSetDialog()
    .subscribe(set => {
      // try create events
      if (set) this.createEvents(courses, set);
    });
  }

  protected openCreateCoursesDialog () {
    this._matDialog.open<CreateCoursesComponent>(CreateCoursesComponent, {
      width:            '80vw',
      maxWidth:         '1200px',
      viewContainerRef: this._viewContainerRef
    });
  }

  protected openOverlapGroupsDialog () {
    this._dialog.openOverlapGroupsDialog(this._viewContainerRef.injector);
  }

  protected connectCourses (selectedCourses: Populated.course[]) {

    // what are the overlap groups of the selected courses
    const overlapGroups = chain(selectedCourses)
      .map(x => x.overlapGroup)
      .filter(Boolean)
      .uniqBy(x => x.id)
      .value();

    // what are the courses connected to the overlap groups
    const connectedCourses = chain(overlapGroups)
      .flatMap(x => (x.coalesced ?? []).map(x => x.to.id))
      .map(id => this.courses$.value.find(x => x.id === id))
      .filter(Boolean)
      .value();

    // all relevant courses and events
    const courses = uniqBy([...selectedCourses, ...connectedCourses], x => x.id);
    const events  = courses.flatMap(x => x.events ?? []);

    // try auto-link and if not possible keep the previous species
    const newSpecies = autoLink(events)
      .map(x => ({ id: nanoid(8), events: x }))
      .flatMap(x => x.events
        .map(e => ({ id: x.id, to: e.id, toModel: 'events' }) as Species<string, 'events'>)
      );
    const previousSpecies = overlapGroups
      .flatMap(x => (x.species ?? [])
        .map(x => ({ id: x.id, to: x.to.id, toModel: 'events' }) as Species<string, 'events'>)
      )
      .filter(x => events.some(e => e.id === x.to));
    const species = newSpecies.length ? newSpecies : previousSpecies;

    const changes: { id: string; coalesced: Coalesced<string, 'courses'>[], species: Species<string, 'events'>[] } = {
      id:        nanoid(8),
      coalesced: courses.map(x => ({ to: x.id, toModel: 'courses' })),
      species:   species
    };

    this._source.set({ collection: 'overlapGroups', did: this.did }, changes);
  }

  protected disconnectCourses (courses: Populated.course[]) {
    const changes = courses.map(x => ({ id: x.id, overlapGroup: null }));
    this._source.set({ collection: 'courses', did: this.did }, changes);
  }

  protected availableLocationsConfig: AvailableLocationConfig = {
    shouldReset: true,
    allowAdd:    true,
    voidText: '-',
  }
}
